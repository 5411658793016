import React, { useState, useEffect } from "react";
import BancoLogo from "../assets/LogoLarge.png";
import TextInput from "../components/TextInput";
import api from "../api";

const CheckTurnPage = () => {
    const [date, setDate] = useState(new Date());
    const [govId, setGovId] = useState(undefined);
    const [found, setFound] = useState(false);
    const [result, setResult] = useState(undefined);
    const [error, setError] = useState(undefined);
    let dateOptions = { weekday: "long", month: "long", day: "numeric" };

    const handleGovId = (field, value) => setGovId(value)

    const handleSubmit = () => {
        api.checkTurn.check({ govId: govId }).then((response) => response.message === 'El paciente no tiene turnos registrados.' ? (setError(response.message)) : (setResult(response[0]), setFound(true))).catch((error) => setError(error.response.data.message))
    }

    return (
        <div className="min-h-screen h-full flex flex-col sm:px-6 bg-gray-50 lg:px-8">
            {found ? (
                <div>
                    <img
                        className="w-1/2 sm:w-1/6 mx-auto mt-24"
                        src={BancoLogo}
                        alt="Banco de Ojos"
                    />
                    <button className="absolute top-4 sm:top-24 left-4 sm:left-16 flex justify-center py-4 px-[50px] border border-primary rounded-md shadow-sm text-sm font-medium text-primary" type="button" onClick={() => (setFound(false), setResult(undefined))}>Volver</button>
                    <div className="mt-12">
                        <p className="font-bold text-2xl sm:text-[30px] text-[#75C9EE] text-center leading-[40px]">
                            PACIENTE
                        </p>
                        <p className="font-bold text-3xl sm:text-[40px] text-[#363636] text-center leading-[36px] sm:mt-2.5">
                            {result.patient}
                        </p>
                    </div>
                    <div className="mt-6 sm:mt-16">
                        <p className="font-bold text-lg sm:text-2xl text-[#75C9EE] text-center leading-[40px]">
                            Posición en la fila
                        </p>
                        <p className="font-bold text-2xl sm:text-5xl text-[#363636] text-center leading-[36px] mt-1 sm:mt-5">
                            #{result.position}
                        </p>
                    </div>
                    <div className="mt-6 sm:mt-16">
                        <p className="font-bold text-lg sm:text-2xl text-[#75C9EE] text-center leading-[40px]">
                            DOCTOR
                        </p>
                        <p className="font-bold text-lg sm:text-2xl text-[#363636] text-center leading-[36px] sm:mt-2.5">
                            Dr. {result.doctor}
                        </p>
                    </div>
                </div>
            ) : (
                <div>
                    <img
                        className="w-1/2 sm:w-1/6 mx-auto mt-24"
                        src={BancoLogo}
                        alt="Banco de Ojos"
                    />
                    <p className="text-2xl sm:text-4xl mt-12 text-center text-primary font-bold leading-[40px]">Verifica el estado de tus consultas</p>
                    <div className="flex flex-col gap-7">
                        <TextInput handleChange={handleGovId} containerClassName='w-full mt-6 sm:mt-20 text-center' label='Introduce tu número de cédula' labelClassName='text-primary mx-auto' className='shadow-sm w-1/2 sm:w-1/4 pl-2 border border-gray-300 block sm:text-sm rounded-md mx-auto' />
                        <button type="button" onClick={() => handleSubmit()} className="w-1/2 sm:w-1/4 mx-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:text-sm">
                            Siguiente
                        </button>
                        {error && <p className="-mt-4 text-sm text-center text-red-600">{error}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckTurnPage;
