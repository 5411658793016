import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FileInput(props) {

    const hiddenFileInput = React.useRef(null);
    const uploadFile = (event) => {
        hiddenFileInput.current.click();
    };

    return (
        <div className={props.containerStyle ? props.containerStyle : ""}>
            {props.label && <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "text-sm font-medium text-gray-700"}>
                {props.label}
            </label>}
            <div className="mt-2 flex flex-row gap-4 align-middle">
                <button type='button' onClick={uploadFile} className="flex justify-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary bg-white">
                    <PlusIcon className="h-5 w-5 text-primary" aria-hidden="true" />
                    {props.buttonText}
                </button>
                <input
                    onChange={(event) => { props.handleChange(props.name, event.target.files[0]) }}
                    type="file"
                    multiple={false}
                    name={props.name}
                    ref={hiddenFileInput}
                    style={{ display: 'none' }} />
                <p className="mt-1 font-normal text-[#6B7280] truncate w-96">{props.value ? props.value.name : 'No se encontraron archivos'}</p>
            </div>
            {props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div>
    )

}