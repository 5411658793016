import React, { useContext, useEffect, useState } from "react";
import {
    Bars3Icon,
} from '@heroicons/react/24/outline'
import api from "../../api";
import Sidebar from "../../components/Sidebar"
import Breadcrumbs from "../../components/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import FormNewConsultation from "../../components/FormNewConsultation";
import InlineCheckbox from "../../components/InlineCheckbox";
import { Dialog } from "@headlessui/react";
import Modal from "../../components/Modal";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Table from "../../components/Table";

let tabs = [
    { name: 'Visión y Tensión', current: true },
    { name: 'Refracción', current: false },
    { name: 'Medicación', current: false },
    { name: 'Estudios oftalmológicos', current: false },
    { name: 'Estudios clínicos', current: false },
    { name: 'Cirugía', current: false },
    { name: 'Ecobiometría', current: false },
]

const columns = [
    { name: 'uploadDate', displayText: 'Fecha' },
    { name: 'name', displayText: 'Nombre del archivo' },
]

const consultationTypes = [
    { name: 'General', value: 1 },
    { name: 'De especialidad', value: 2 },
]

const constancyTypes = [
    { name: 'Constancia de reposo', value: 1 },
    { name: 'Asistencia a consulta', value: 2 },
]

const consultationASubtypes = [
    { name: 'Control de rutina', value: 4 },
    { name: 'Control por tratamiento', value: 1 },
    { name: 'Control post quirúrgico', value: 2 },
    { name: 'Estudios', value: 3 },

]

const consultationBSubtypes = [
    { name: 'Control por tratamiento', value: 1 },
    { name: 'Control post quirúrgico', value: 2 },
    { name: 'Estudios', value: 3 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export default function ConsultationNewPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [history, setHistory] = useState([])
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingSubmitRecords, setLoadingSubmitRecords] = useState(false)
    const [loadingSubmitConstancy, setLoadingSubmitConstancy] = useState(false)
    const [openRecords, setOpenRecords] = useState(false)
    const [openConstancies, setOpenConstancies] = useState(false)
    const [openDocuments, setOpenDocuments] = useState(false)
    const [constancyType, setConstancyType] = useState(1)
    const [constancy, setConstancy] = useState(undefined)
    const [documents, setDocuments] = useState([])
    const [submitError, setSubmitError] = useState('')
    const [downloadError, setDownloadError] = useState('')
    const [hasSelectedType, setHasSelectedType] = useState(true)
    const [hasSelectedSubtype, setHasSelectedSubtype] = useState(true)
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const { idPatient } = useParams()
    const { idConsultation } = useParams()
    const [tab, setTab] = useState('Visión y Tensión')
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('BDOuserData'))?.id)
    const [patient, setPatient] = useState([])
    const [medicines, setMedicines] = useState([])
    const [surgeries, setSurgeries] = useState([])
    const [doctors, setDoctors] = useState([])
    const navigate = useNavigate();
    const [consultation, setConsultation] = useState({
        patient: parseInt(idPatient),
        doctor: userId,
        consultationDate: new Date().toISOString().split('T')[0],
        consultationCategory: 2,
        consultationType: 1,
    })
    const [errors, setErrors] = useState({
    });
    const handleChange = (field, value) => setConsultation((prev) => ({ ...prev, [field]: value }));
    const handlePatient = (field, value) => setPatient((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }))
    const handleConstancyType = (field, value) => setConstancyType(value)
    const handleConstancy = (field, value) => {
        if (field === 'restDays')
            setConstancy((prev) => ({ ...prev, [field]: parseInt(value) }))
        else
            setConstancy((prev) => ({ ...prev, [field]: value }))
    };
    const today = new Date();

    useEffect(() => {
        api.patients.getHistory(idPatient, 'date').then((response) => (setHistory({ ...response, consultationList: response.consultationList ? response.consultationList.map((element, idx) => ({ ...element, index: idx + 1, prescription: element.prescription ? 'Si' : 'No' })) : undefined }), setLoading(false)))
        api.patients.getOne(idPatient).then((response) => {
            setPatient({
                ...response,
                name: response.firstName + " " + response.lastName,
            });
            api.documents.getMulti(null, idPatient).then((response) => {
                setDocuments(response.map((element) => ({ id: element.id, name: element.name, uploadedBy: element.uploadedBy.name, uploadDate: new Date(element.uploadDate).toLocaleDateString(), document: element.document })))
            })
        }).catch((error) => console.log(error))
        api.medicines.getMulti().then((response) => {
            setMedicines(response)
        })
        api.surgeries.getMulti().then((response) => {
            setSurgeries(response)
        })
        api.users.getDoctors().then((response) => {
            setDoctors(response.map((element) => ({ name: `${element.sex === 'Masculino' ? 'Dr.' : 'Dra.'} ${element.firstName} ${element.lastName}`, ...element })))
        })
    }, [])

    useEffect(() => {
        if (idConsultation)
            api.consultations.getOne(idConsultation).then((response) => {
                setConsultation(response)
            })
    }, [idConsultation])

    useEffect(() => {
        if (openConstancies)
            setConstancy({
                patient: parseInt(idPatient),
                doctor: userId,
            })
        setDownloadError('')
    }, [openConstancies])

    useEffect(() => {
        if (constancyType === 2)
            setConstancy({
                patient: parseInt(idPatient),
                doctor: userId,
                text: `Certifico por la presente que ${patient?.sex === 1 ? 'el' : 'la'} paciente ${patient?.name} acudió a la consulta oftalmológica en fecha ${today.getDate()} de ${today.toLocaleString('es-PY', { month: 'long' })} de ${today.getFullYear()}.
\nSe expide este certificado para lo que hubiere lugar.`
            })
        else if (constancyType)
            setConstancy({
                patient: parseInt(idPatient),
                doctor: userId,
            })
        setDownloadError('')
    }, [constancyType])

    useEffect(() => {
        tabs[tabs.findIndex((tab) => tab.current)].current = false
        tabs[tabs.findIndex((tab) => tab.name === 'Visión y Tensión')].current = true
    }, [])

    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: `${patient.name}`, href: `/patients/${idPatient}`, current: false },
        { name: idConsultation ? 'Ver consulta' : 'Nueva consulta', href: '#', current: true },
    ]

    const changeTab = ((value) => {
        let error = false
        if (tabs[tabs.findIndex((tab) => tab.current)].name == 'Visión y Tensión') {
            if (!error) {
                tabs[tabs.findIndex((tab) => tab.current)].current = false
                tabs[tabs.findIndex((tab) => tab.name === value)].current = true
                setTab(tabs[tabs.findIndex((tab) => tab.current)].name)
            }
        } else {
            tabs[tabs.findIndex((tab) => tab.current)].current = false
            tabs[tabs.findIndex((tab) => tab.name === value)].current = true
            setTab(tabs[tabs.findIndex((tab) => tab.current)].name)
        }
    })

    const checkIfEmpty = (medication) => {
        if (medication.medicine || medication.periodNumber !== "" || medication.quantity !== "" || medication.frequencyNumber !== "" || medication.observations !== "")
            return false
        else
            return true
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        console.log('error: ', error)
        if (!error) {
            let request = { ...consultation }
            setLoadingSubmit(true)
            setSubmitError('')
            if (request.medications && request.medications.map((element) => checkIfEmpty(element) ? null : element).every((element) => element === null))
                request = { ...request, medications: [] }
            else if (request.medications)
                request = { ...request, medications: request.medications.map((element) => element.period === 5 ? ({ ...element, periodNumber: 0 }) : element) }
            if (idConsultation) {
                api.consultations.update(idConsultation, {
                    ...request, visionTension: { ...request.visionTension, diagnoses: typeof request.visionTension.diagnoses === 'object' ? request.visionTension.diagnoses.map((element) => element) : request.visionTension.diagnoses.map((element) => element) }
                }).then((response) => navigate(`/patients/${idPatient}`)).catch((error) => {
                    setSubmitError(error.message);
                    if (error.message.visionTension || error.message.tensionRight || error.message.tensionLeft || error.message.sampleTime)
                        changeTab('Visión y Tensión')
                    else if (error.message.refraction)
                        changeTab('Refracción')
                    else if (error.message.medications)
                        changeTab('Medicación')
                    else if (error.message.ophthalmologicalStudy)
                        changeTab('Estudios Oftalmológicos')
                    else if (error.message.clinicalStudy)
                        changeTab('Estudios Clínicos')
                    else if (error.message.surgery)
                        changeTab('Cirugía')
                    else if (error.message.ecobiometry)
                        setLoadingSubmit(false)
                })
            } else {
                api.consultations.create({
                    ...request, visionTension: { ...request.visionTension, diagnoses: typeof request.visionTension.diagnoses === 'object' ? request.visionTension.diagnoses.map((element) => element) : request.visionTension.diagnoses.map((element) => element) }
                }).then((response) => navigate(`/patients/${idPatient}`)).catch((error) => {
                    setSubmitError(error.message);
                    if (error.message.visionTension || error.message.tensionRight || error.message.tensionLeft || error.message.sampleTime)
                        changeTab('Visión y Tensión')
                    else if (error.message.refraction)
                        changeTab('Refracción')
                    else if (error.message.medications)
                        changeTab('Medicación')
                    else if (error.message.ophthalmologicalStudy)
                        changeTab('Estudios Oftalmológicos')
                    else if (error.message.clinicalStudy)
                        changeTab('Estudios Clínicos')
                    else if (error.message.surgery)
                        changeTab('Cirugía')
                    setLoadingSubmit(false)
                })
            }
        }
    }

    const handleSubmitPatient = () => {
        setLoadingSubmitRecords(true)
        api.patients.update(idPatient, patient).then((response) => {
            console.log(response)
            setLoadingSubmitRecords(false);
            setOpenRecords(false);
        }).catch((error) => { setLoadingSubmitRecords(false) })
    }

    const submitConstancy = () => {
        if (constancyType) {
            setLoadingSubmitConstancy(true)
            setDownloadError('')
            if (constancyType === 1)
                api.doctorsNote.get({ ...constancy, diagnosisId: consultation?.visionTension?.diagnoses?.[0]?.id }).then(res => {
                    setLoadingSubmitConstancy(false)
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ConstanciaReposo.pdf');
                    document.body.appendChild(link);
                    link.click();
                }, async (err) => {
                    setDownloadError(JSON.parse(await err.response.data.text()).detail)
                    setLoadingSubmitConstancy(false)
                })
            else if (constancyType === 2)
                api.consultationAttendance.get(constancy).then(res => {
                    setLoadingSubmitConstancy(false)
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'AsistenciaConsulta.pdf');
                    document.body.appendChild(link);
                    link.click();
                }, async (err) => {
                    setDownloadError(JSON.parse(await err.response.data.text()).detail)
                    setLoadingSubmitConstancy(false)
                })
        }
    }

    const patientRecords = (
        <>
            <div>
                <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Antecedentes del paciente
                    </Dialog.Title>
                    <div className="flex-col space-y-4 py-5 sm:p-6">
                        <div>
                            <label className="font-semibold text-gray-700">Antecedente personal</label>
                        </div>
                        <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                            <InlineCheckbox value={patient?.dm} handleChange={handlePatient} name="dm" label="DM" />
                            <InlineCheckbox value={patient?.hta} handleChange={handlePatient} name="hta" label="HTA" />
                            <InlineCheckbox value={patient?.gl} handleChange={handlePatient} name="gl" label="Glaucoma" />
                        </div>
                        <div>
                            <label className="font-semibold text-gray-700">Antecedente ocular</label>
                        </div>
                        <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                            <InlineCheckbox value={patient?.strabismus} handleChange={handlePatient} name="strabismus" label="Estrabismo" />
                            <InlineCheckbox value={patient?.glaucoma} handleChange={handlePatient} name="glaucoma" label="Glaucoma" />
                        </div>
                        <div>
                            <label className="font-semibold text-gray-700">Cirugías oftalmológicas</label>
                        </div>
                        <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                            <InlineCheckbox value={patient?.cataractSurgery} handleChange={handlePatient} name="cataractSurgery" label="Cataratas" />
                            <InlineCheckbox value={patient?.glaucomaSurgery} handleChange={handlePatient} name="glaucomaSurgery" label="Glaucoma" />
                            <InlineCheckbox value={patient?.refractiveSurgery} handleChange={handlePatient} name="refractiveSurgery" label="Refractiva" />
                        </div>
                        <div className="mt-10">
                            <label className="font-semibold text-gray-700">Antecedentes familiares</label>
                        </div>
                        <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2 -ml-2">
                            <label className="invisible">Esquina</label>
                            <label>Diabetes</label>
                            <label>HTA</label>
                            <label className="-ml-10">Glaucoma</label>
                        </div>
                        <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                            <label className="font-medium text-sm">Padre</label>
                            <InlineCheckbox value={patient?.fatherDm} handleChange={handlePatient} name="fatherDm" />
                            <InlineCheckbox value={patient?.fatherHta} handleChange={handlePatient} name="fatherHta" />
                            <InlineCheckbox value={patient?.fatherGlaucoma} handleChange={handlePatient} name="fatherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                        </div>
                        <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                            <label className="font-medium text-sm">Madre</label>
                            <InlineCheckbox value={patient?.motherDm} handleChange={handlePatient} name="motherDm" />
                            <InlineCheckbox value={patient?.motherHta} handleChange={handlePatient} name="motherHta" />
                            <InlineCheckbox value={patient?.motherGlaucoma} handleChange={handlePatient} name="motherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                        </div>
                        <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                            <label className="font-medium text-sm">Hermanos</label>
                            <InlineCheckbox value={patient?.siblingsDm} handleChange={handlePatient} name="siblingsDm" />
                            <InlineCheckbox value={patient?.siblingsHta} handleChange={handlePatient} name="siblingsHta" />
                            <InlineCheckbox value={patient?.siblingsGlaucoma} handleChange={handlePatient} name="siblingsGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                        </div>
                        <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                            <label className="font-medium text-sm">Otros</label>
                            <InlineCheckbox value={patient?.otherDm} handleChange={handlePatient} name="otherDm" />
                            <InlineCheckbox value={patient?.otherHta} handleChange={handlePatient} name="otherHta" />
                            <InlineCheckbox value={patient?.otherGlaucoma} handleChange={handlePatient} name="otherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => {
                        handleSubmitPatient()
                    }}
                >
                    {loadingSubmitRecords ?
                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                        'Guardar'
                    }
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenRecords(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const constancies = (
        <div>
            <div>
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-semibold text-gray-900">
                        Generar constancia
                    </Dialog.Title>
                    <div className="flex-col space-y-4 py-5 sm:p-6">
                        <SelectInput className='shadow-sm w-full mt-1 mx-auto pr-12 border border-gray-300 block sm:text-sm rounded-md' containerStyle='w-fit mx-auto' value={constancyType} handleChange={handleConstancyType} options={constancyTypes.map((element) => (<option value={element.value} label={element.name}>{element.name}</option>))} />
                    </div>
                    {constancyType === 1 && <>
                        <div className="flex gap-3 w-fit">
                            <p className="mt-2">Días de reposo</p>
                            <TextInput min={1} containerClassName='w-fit' className='shadow-sm pl-2 block w-1/2 sm:text-sm rounded-md' value={constancy?.restDays} name='restDays' handleChange={handleConstancy} type='number' />
                        </div>
                    </>}
                    {constancyType === 2 && <>
                        <TextArea containerClassName='w-[450px]' label='Descripción' value={constancy?.text} name='text' handleChange={handleConstancy} />
                    </>}
                </div>
            </div>
            {downloadError && <p className="mt-3 text-sm text-red-600">{downloadError}</p>}
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => submitConstancy()}
                >
                    {loadingSubmitConstancy ?
                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                        'Aceptar'
                    }
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenConstancies(false)}
                >
                    Cancelar
                </button>
            </div>
        </div>
    )

    const documentModal = (
        <div>
            <div>
                <div className="text-center flex flex-col gap-6">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-semibold text-gray-900">
                        Documentos
                    </Dialog.Title>
                    <div className="text-left">
                        {documents.length > 0 && <Table actiontext='Ver' setSelectedDocument={setSelectedDocument} document={true} columns={columns} rows={documents} />}
                        {documents.length === 0 && <p className="text-center">No se han encontrado documentos</p>}
                    </div>
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                        onClick={() => setOpenDocuments(false)}
                    >
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    )

    return (
        <div className="bg-gray-100 min-h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <Modal content={patientRecords} open={openRecords} setOpen={setOpenRecords} size='sm:max-w-4xl w-full' />
            <Modal content={constancies} open={openConstancies} setOpen={setOpenConstancies} size='sm:max-w-2xl w-fit' />
            <Modal content={documentModal} open={openDocuments} setOpen={setOpenDocuments} size='sm:max-w-2xl w-full' />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                {hasSelectedSubtype || idConsultation ?
                    <>
                        <div className="flex flex-wrap sm:flex-nowrap justify-between flex-1 sm:ml-6 mt-6 gap-y-4">
                            <Breadcrumbs pages={pages} />
                            <div className="flex gap-3">
                                <button
                                    type="button"
                                    onClick={() => setOpenRecords(true)}
                                    className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-primary text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Antecedentes
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setOpenConstancies(true)}
                                    className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-primary text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Constancias
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setOpenDocuments(true)}
                                    className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-primary text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Documentos
                                </button>
                            </div>
                            {idConsultation ? <button
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                                className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            >
                                {loadingSubmit ?
                                    <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Guardar'
                                }
                            </button> : <button
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                                className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            >
                                {loadingSubmit ?
                                    <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Finalizar'
                                }
                            </button>}
                        </div>
                        {submitError.nonFieldErrors && <div className="px-12 mt-4 text-sm text-red-600 text-right">
                            {submitError.nonFieldErrors}
                        </div>}
                        <main className="flex flex-1 flex-col lg:flex-row">
                            <div className="mt-8 w-full px-12">
                                <div className="sm:hidden mx-4 mb-4">
                                    <label htmlFor="tabs" className="sr-only">
                                        Seleccione una página
                                    </label>
                                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                    <select
                                        onChange={(event) => changeTab(event.target.value)}
                                        id="tabs"
                                        name="tabs"
                                        className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                        defaultValue={tabs.find((tab) => tab.current).name}
                                    >
                                        {tabs.map((tab) => (
                                            <option key={tab.name}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="hidden sm:block mb-4">
                                    <nav className="flex flex-wrap gap-x-4 gap-y-2" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <button
                                                type="button"
                                                key={tab.name}
                                                className={classNames(
                                                    tab.current ? 'bg-[#C8E5F2] text-[#48B7E9]' : 'text-gray-500 hover:text-gray-700',
                                                    'px-3 py-2 font-semibold text-xs rounded-md uppercase leading-4'
                                                )}
                                                onClick={() => changeTab(tab.name)}
                                                aria-current={tab.current ? 'page' : undefined}
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                                <div className="mx-4 lg:ml-0">
                                    <div className="bg-white my-2 max-w-screen shadow border flex-1 border-gray-300 rounded-lg divide-y divide-gray-800">
                                        <form>
                                            <FormNewConsultation
                                                idConsultation={idConsultation}
                                                medicines={medicines}
                                                surgeries={surgeries}
                                                doctors={doctors}
                                                userId={userId}
                                                handleSubmit={handleSubmit}
                                                handleChange={handleChange}
                                                refractionRecords={history.consultationList ? history?.consultationList.filter((element) => element.refraction) : undefined}
                                                medicationsRecords={history.consultationList ? history?.consultationList.filter((element) => element.medications) : undefined}
                                                loadingSubmit={loadingSubmit}
                                                consultation={consultation}
                                                changeTab={changeTab}
                                                tab={tab}
                                                age={getAge(patient?.birthdate)}
                                                handleError={handleError}
                                                errors={submitError}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                    :
                    <>
                        <div className="flex flex-col justify-between flex-1 mt-6 gap-y-4">
                            <div className="ml-4">
                                <Breadcrumbs pages={pages} />
                            </div>
                            <main className="flex flex-1 flex-col lg:flex-row h-full">
                                <div className="mt-8 w-full h-full">
                                    <main className="flex-1 bg-gray-50 px-32 py-16 h-full">
                                        <p className="font-bold text-[30px] text-[#4B5563] leading-[36px] text-center">Seleccioná el tipo de consulta</p>
                                        <div className="grid grid-cols-1 w-full mt-10 justify-center items-center gap-4">
                                            {hasSelectedType ?
                                                <>
                                                    {
                                                        consultation.consultationCategory === 1 ? consultationASubtypes.map((type) => (
                                                            <div
                                                                key={type.name}
                                                                onClick={() => (handleChange('consultationType', type.value), setHasSelectedSubtype(true))}
                                                                className="relative w-3/5 mx-auto cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                                                            >
                                                                <p className="w-fit mx-auto">{type.name}</p>
                                                            </div>
                                                        ))
                                                            :
                                                            consultationBSubtypes.map((type) => (
                                                                <div
                                                                    key={type.name}
                                                                    onClick={() => (handleChange('consultationType', type.value), setHasSelectedSubtype(true))}
                                                                    className="relative w-3/5 mx-auto cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                                                                >
                                                                    <p className="w-fit mx-auto">{type.name}</p>
                                                                </div>
                                                            ))
                                                    }
                                                </>
                                                :
                                                consultationTypes.map((type) => (
                                                    <div
                                                        key={type.name}
                                                        onClick={() => (handleChange('consultationCategory', type.value), setHasSelectedType(true))}
                                                        className="relative w-3/5 mx-auto cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                                                    >
                                                        <p className="w-fit mx-auto">{type.name}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </main>
                                </div>
                            </main>
                        </div>
                    </>
                }
            </div>
        </div >
    );
}
