import { useEffect, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SimpleCombobox({ name, label, containerClassName, inputClassName, handleChange, options, Idx, error, value, required, create }) {
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(undefined)
    const [firstRender, setFirstRender] = useState(false)
    const [complete, setComplete] = useState(false)

    useEffect(() => {
        if (value && !firstRender) {
            setQuery(value.name)
            setSelectedPerson(value)
            setFirstRender(true)
        }
    }, [value])

    useEffect(() => {
        if (selectedPerson && (Idx || Idx === 0)) {
            setQuery(selectedPerson.name)
            handleChange(name, selectedPerson, Idx)
            setComplete(true)
        } else if (selectedPerson) {
            setQuery(selectedPerson.name)
            handleChange(name, selectedPerson)
        }
    }, [selectedPerson])

    useEffect(() => {
        console.log()
        if (options.find((element) => element.name.toLowerCase() === query.toLowerCase())) {
            setSelectedPerson(options.find((element) => element.name.toLowerCase() === query.toLowerCase()))
        } else if (query !== selectedPerson?.name && (Idx || Idx === 0)) {
            setComplete(false)
            setSelectedPerson(undefined)
            handleChange(name, undefined, Idx)
        } else if (query !== selectedPerson?.name) {
            setSelectedPerson(undefined)
            handleChange(name, undefined)
        }
    }, [query])

    const filteredPeople =
        (query === '' || query === undefined)
            ? options
            : options.filter((option) => {
                return option.name.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <Combobox as="div" className={containerClassName} value={selectedPerson} onChange={setSelectedPerson}>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">{label}{required ? <p className="font-medium text-[#F51515] inline-block ml-2"> *</p> : null}</Combobox.Label>
            <div className={classNames(inputClassName ? inputClassName : "relative mt-2")}>
                <Combobox.Input
                    className={classNames(error ? 'border border-red-500' : ' border-0', create ? 'pr-16' : 'pr-8', "w-full rounded-md bg-white py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6")}
                    onChange={(event) => { setQuery(event.target.value) }}
                    value={query}
                />
                <Combobox.Button className={classNames(create ? 'right-8' : 'right-0', "absolute inset-y-0 flex gap-2 items-center rounded-r-md px-2 focus:outline-none")}>
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {create && <div className="absolute inset-y-0 right-0 flex gap-2 items-center rounded-r-md px-2 focus:outline-none">
                    {complete ? <CheckIcon className="h-5 w-5 text-primary" aria-hidden="true" /> : <PlusIcon onClick={query ? () => { create({ name: query }).then((response) => setSelectedPerson(response)) } : null} className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />}
                </div>}
                {filteredPeople.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-primary text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                <>
                                    <span className={classNames('block truncate')}>{person.name}</span>
                                </>
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
            {
                error && <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                    {error}
                </p>
            }
        </Combobox>
    )
}
