import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Badge(props) {

    return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-[#DBEAFE] px-2 py-1 text-xs font-medium text-[#1E40AF] ring-1 ring-inset ring-gray-500/10">
            {props.text}
            <button onClick={props.handleDelete} type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-[#1E40AF]/20">
                <span className="sr-only">Remove</span>
                <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-[#38BDF8] group-hover:stroke-[#38BDF8]">
                    <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
            </button>
        </span>
    )

}