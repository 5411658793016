import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";

import useWebSocket, { ReadyState } from "react-use-websocket";

import { useAuth } from "../../context/authContext";
import api from "../../api";
import CallerStep1 from "../../components/steps/CallerStep1";
import CallerStep2 from "../../components/steps/CallerStep2";
import CallerStep3 from "../../components/steps/CallerStep3";
import CallerList from "../../components/steps/CallerList";

const PatientCallerPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { userData } = useAuth();
    const [medicalOffices, setMedicalOffices] = useState([]);
    const [selectedMedicalOffice, setSelectedMedicalOffice] = useState("");
    const [step, setStep] = useState(1);
    const [connectionStatus, setConnectionStatus] = useState(null);
    const [consultationType, setConsultationType] = useState("general");

    const url = `${process.env.REACT_APP_WS_URL}`;

    useEffect(() => {
        const getMedicalOffices = async () => {
            const res = await api.medicalOffices.getMulti();
            setMedicalOffices(res);
            setLoading(false);
        };
        getMedicalOffices();
    }, []);

    const handleChange = (field, value) => {
        setSelectedMedicalOffice(value);
    };

    const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
        useWebSocket(url, {
            onOpen: () => setConnectionStatus("open"),
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => true,
            share: true,
        });

    // Run when a new WebSocket message is received (lastJsonMessage)
    useEffect(() => {
        if (
            lastJsonMessage?.status === "success" &&
            lastJsonMessage?.type === "connection" &&
            step !== 2
        ) {
            setStep(2);
        } else if (
            lastJsonMessage?.type === "no_patient" &&
            lastJsonMessage?.status === "pass"
        ) {
            setStep(3);
        }
    }, [lastJsonMessage]);

    const connectQueue = () => {
        if (readyState === ReadyState.OPEN) {
            if (consultationType === "general") {
                sendJsonMessage(
                    {
                        action: "join_general_queue_group",
                    },
                    false
                );
            } else {
                sendJsonMessage(
                    {
                        action: "join_medical_office_group",
                        data: {
                            medical_office_id: selectedMedicalOffice,
                            doctor_id: userData.id,
                        },
                    },
                    false
                );
            }
        }
    };

    const getStep = () => {
        if (loading) {
            return (
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
            );
        }
        if (step === 1) {
            return (
                <CallerStep1
                    userData={userData}
                    selectedMedicalOffice={selectedMedicalOffice}
                    handleChange={handleChange}
                    loading={loading}
                    medicalOffices={medicalOffices}
                    connectQueue={connectQueue}
                    setConsultationType={setConsultationType}
                />
            );
        } else if (step === 2 && consultationType === "general") {
            return (
                <CallerStep2
                    userData={userData}
                    sendJsonMessage={sendJsonMessage}
                    lastJsonMessage={lastJsonMessage}
                    selectedMedicalOffice={selectedMedicalOffice}
                    setStep={setStep}
                    consultationType={consultationType}
                />
            );
        } else if (step === 2 && consultationType !== "general") {
            return (
                <CallerList
                    userData={userData}
                    sendJsonMessage={sendJsonMessage}
                    lastJsonMessage={lastJsonMessage}
                    selectedMedicalOffice={selectedMedicalOffice}
                    setStep={setStep}
                    consultationType={consultationType}
                />
            );
        } else if (step === 3) {
            return <CallerStep3 />;
        }
    };

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                currentScreen={"Turnero"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="grid h-screen place-items-center">
                    {getStep()}
                </div>
            </div>
        </div>
    );
};

export default PatientCallerPage;
