import React, { useEffect } from "react";
import { useState, Fragment } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import { NoSymbolIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import api from "../api";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Table from "../components/Table";
import SimpleCombobox from "../components/SimpleCombobox"
import Modal from "../components/Modal";
import { Menu, Dialog, Transition } from "@headlessui/react";
import Badge from "../components/Badge";

const columns = [
    { name: 'position', displayText: 'Posición' },
    { name: 'patientName', displayText: 'Paciente' },
    { name: 'govId', displayText: 'C.I.' },
    { name: 'age', displayText: 'Edad' },
    { name: 'doctorName', displayText: 'Doctor' },
    { name: 'showCalled', displayText: 'Fue llamado?' },
    { name: 'showAttended', displayText: 'Fue atendido?' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TurnsPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [doctors, setDoctors] = useState([])
    const [loading, setLoading] = useState(true)
    const [turns, setTurns] = useState(undefined)
    const [open, setOpen] = useState(false)
    const [selectedTurn, setSelectedTurn] = useState(undefined)
    const [selectedDoctor, setSelectedDoctor] = useState(undefined)
    const [filter, setFilter] = useState(undefined)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [error, setError] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState(undefined)
    const [today, setToday] = new Date().toISOString().split('T')

    const handleOpen = (value) => {
        setSelectedTurn(value);
        setOpen(true);
    }

    useEffect(() => {
        api.users.getDoctors().then((response) => {
            setDoctors([{ id: 0, name: 'Consulta General' }, ...response.map((element) => ({ name: `${element.sex === 1 ? 'Dr.' : 'Dra.'} ${element.firstName} ${element.lastName}`, ...element }))])
            api.turns.getMulti(null, null, null, null, { date: today }).then((turns) => {
                setTurns(
                    turns.map((element) => ({
                        ...element, patientName: element.patient.firstName + ' ' + element.patient.lastName, patient: element.patient.id,
                        doctorName: element.doctor ? element.doctor.firstName + ' ' + element.doctor.lastName : 'Consulta General', govId: element.patient.govId, age: element.patient.age,
                        showCalled: element.called ? 'Sí' : 'No', showAttended: element.attended ? 'Sí' : 'No', doctor: element.doctor ? { ...element.doctor, name: `${element.doctor.sex === 1 ? 'Dr.' : 'Dra.'} ${element.doctor.firstName} ${element.doctor.lastName}` } : { id: 0, name: 'Consulta General' }
                    })))
                setLoading(false)
            })
        })
    }, [])

    useEffect(() => {
        if (filter || filter === 0) {
            api.turns.getMulti(null, null, null, null, { doctor: filter === 0 ? 0 : filter.id, date: today }).then((turns) => {
                setTurns(
                    turns.map((element) => ({
                        ...element, patientName: element.patient.firstName + ' ' + element.patient.lastName, patient: element.patient.id,
                        doctorName: element.doctor ? element.doctor.firstName + ' ' + element.doctor.lastName : 'Consulta General', govId: element.patient.govId, age: element.patient.age,
                        showCalled: element.called ? 'Sí' : 'No', showAttended: element.attended ? 'Sí' : 'No', doctor: element.doctor ? { ...element.doctor, name: `${element.doctor.sex === 1 ? 'Dr.' : 'Dra.'} ${element.doctor.firstName} ${element.doctor.lastName}` } : { id: 0, name: 'Consulta General' }
                    })))
                setLoading(false)
            })
        } else {
            api.turns.getMulti(null, null, null, null, { date: today }).then((turns) => {
                setTurns(
                    turns.map((element) => ({
                        ...element, patientName: element.patient.firstName + ' ' + element.patient.lastName, patient: element.patient.id,
                        doctorName: element.doctor ? element.doctor.firstName + ' ' + element.doctor.lastName : 'Consulta General', govId: element.patient.govId, age: element.patient.age,
                        showCalled: element.called ? 'Sí' : 'No', showAttended: element.attended ? 'Sí' : 'No', doctor: element.doctor ? { ...element.doctor, name: `${element.doctor.sex === 1 ? 'Dr.' : 'Dra.'} ${element.doctor.firstName} ${element.doctor.lastName}` } : { id: 0, name: 'Consulta General' }
                    })))
                setLoading(false)
            })
        }
    }, [filter])

    const handleDoctor = (field, value) => {
        setSelectedTurn({ ...selectedTurn, doctor: value?.id })
    }

    const handleFilter = (field, value) => {
        setSelectedDoctor(value)
    }

    const deleteFilter = () => {
        setSelectedDoctor(undefined)
        setFilter(undefined)
    }

    const saveFilter = () => {
        setFilter(selectedDoctor)
    }

    const handleSubmit = () => {
        setSelectedDoctor(undefined)
        setFilter(undefined)
        api.turns.update(selectedTurn.id, selectedTurn).then(() =>
            api.turns.getMulti().then((turns) => {
                setTurns(
                    turns.map((element) => ({
                        ...element, patientName: element.patient.firstName + ' ' + element.patient.lastName, patient: element.patient.id,
                        doctorName: element.doctor ? element.doctor.firstName + ' ' + element.doctor.lastName : 'Consulta General', govId: element.patient.govId, age: element.patient.age,
                        showCalled: element.called ? 'Sí' : 'No', showAttended: element.attended ? 'Sí' : 'No', doctor: element.doctor ? { ...element.doctor, name: `${element.doctor.sex === 1 ? 'Dr.' : 'Dra.'} ${element.doctor.firstName} ${element.doctor.lastName}` } : { id: 0, name: 'Consulta General' }
                    })))
                setLoading(false)
                setOpenConfirmation(false)
            }))
    }

    const editModal = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Editar turno
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Seleccione el doctor que atenderá al paciente
                        </label>
                        <div className="flex gap-3 w-full justify-center relative rounded-md mt-3">
                            {selectedTurn && <SimpleCombobox value={selectedTurn?.doctor} containerClassName='shadow-sm w-2/3' handleChange={handleDoctor} name="doctor" options={doctors} />}
                        </div>
                        {error && <p className="mt-2 text-sm text-red-600">
                            {error}
                        </p>}
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => (setOpen(false), setOpenConfirmation(true))}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => (setOpen(false), setSelectedTurn(undefined))}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const confirmation = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Confirmación
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            ¿Está seguro de que quiere agregar el paciente al turnero?
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto">
                            {errorMessage && <p className="mt-2 text-sm text-red-600">
                                {errorMessage}
                            </p>}
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => handleSubmit()}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => (setOpenConfirmation(false), setOpen(true))}
                >
                    Regresar
                </button>
            </div>
        </>
    )

    return (
        <div className="bg-gray-100">
            <Sidebar currentScreen={'Turnos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div> :
                    <>
                        <div className="flex gap-6 bg-white p-4">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm text-white ring-0 outline-0">
                                        Doctor
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-4 flex flex-col gap-3 px-5">
                                            <SimpleCombobox value={selectedDoctor} handleChange={handleFilter} name='doctor' label='Doctor' options={doctors} />
                                            <div className="flex gap-3">
                                                <button onClick={() => deleteFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                    Borrar filtro
                                                </button>
                                                <button onClick={() => saveFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                    Aplicar filtro
                                                </button>
                                            </div>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        <Modal size='sm:max-w-lg w-full' content={editModal} open={open} setOpen={setOpen} />
                        <Modal size='sm:max-w-lg w-full' content={confirmation} open={openConfirmation} setOpen={setOpenConfirmation} />
                        <main className="flex-1 bg-gray-100">
                            {(filter || filter === 0) &&
                                <div className="p-2">
                                    <Badge text={`Doctor: ${filter.name}`} handleDelete={() => deleteFilter()} />
                                </div>
                            }
                            {turns.length ?
                                <div className="py-6">
                                    <div className="px-4 sm:px-6 md:px-8">
                                        <Table columns={columns} rows={turns} openModal={handleOpen} />
                                    </div>
                                </div>
                                :
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <NoSymbolIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                    </div>
                                </div>
                            }
                        </main>
                    </>
                }
            </div>
        </div >
    );
}
