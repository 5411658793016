import axios from "axios";

const errorCodes = {
    badRequest: "badRequest",
    unauthorized: "unauthorized",
    forbidden: "forbidden",
    notFound: "notFound",
    serverError: "serverError",
    unexpected: "unexpected",
    invalidCredentials: "invalidCredentials",
};

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({
                code: errorCodes.badRequest,
                message: error.response.data,
            });
        if (error.response?.status === 401)
            return Promise.reject(errorCodes.unauthorized);
        if (error.response?.status === 403)
            return Promise.reject(errorCodes.forbidden);
        if (error.response?.status === 404)
            return Promise.reject(errorCodes.notFound);
        if (500 <= error.response?.status <= 599)
            return Promise.reject(errorCodes.serverError);
        return Promise.reject(errorCodes.unexpected);
    }

    async getMulti(
        ordering = null,
        search = null,
        limit = null,
        offset = null,
        extra = {}
    ) {
        const params = { limit, offset, ...extra };
        if (ordering) params.order_by = ordering;
        if (search && search.length > 0) params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, {
                params,
            });
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error);
        }
    }

    async getOne(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async update(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(
                    `${this.collectionUrl}/${id}/`,
                    data
                );
            else
                response = await axios.put(
                    `${this.collectionUrl}/${id}/`,
                    data
                );
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`${this.collectionUrl}/${id}/`);
            return response;
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class UserAPI extends API {
    async getToken(email, password) {
        try {
            const response = await axios.post(`${this.collectionUrl}/token/`, {
                email,
                password,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials);
        }
    }

    async changePassword(newPass) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/me/change-password/`,
                { new: newPass }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async getMe() {
        try {
            const response = await axios.get(`${this.collectionUrl}/me/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async getDoctors() {
        try {
            const response = await axios.get(`${this.collectionUrl}/doctors/`);
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error);
        }
    }
}

class PasswordAPI extends API {
    async sendEmail(email) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, {
                email: email,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async changePassword(password, uuid, token) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/${uuid}/${token}/`,
                { password: password }
            );
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class DownloadAPI extends API {
    async get(consultation) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/export/`,
                consultation,
                {
                    responseType: "blob",
                }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

class PatientsAPI extends API {
    async getHistory(id, order) {
        try {
            const response = await axios.get(
                `${this.collectionUrl}/${id}/history/`,
                { params: { order_by: order } }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class TurnAPI extends API {
    async check(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

class ReportsAPI extends API {
    async getReports(params) {
        try {
            var request = {
                params: params,
            };
            const response = await axios.get(`${this.collectionUrl}/`, request);
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error);
        }
    }
}

const api = {
    districts: new API("districts"),
    consultations: new API("consultations"),
    establishments: new API("establishments"),
    patients: new PatientsAPI("patients"),
    documents: new API("patient-documents"),
    medicines: new API("medicines"),
    surgeries: new API("surgeries"),
    cities: new API("cities"),
    specialties: new API("specialties"),
    diagnostics: new API("diagnostics"),
    refraction: new DownloadAPI("consultations/refraction"),
    medication: new DownloadAPI("consultations/medication"),
    ophthalmologicalStudy: new DownloadAPI(
        "consultations/ophthalmological-study"
    ),
    clinicalStudy: new DownloadAPI("consultations/clinical-study"),
    surgeryOrder: new DownloadAPI("consultations/surgery-order"),
    surgicalEvaluation: new DownloadAPI("consultations/surgical-evaluation"),
    doctorsNote: new DownloadAPI("consultations/doctors-note"),
    consultationAttendance: new DownloadAPI("consultations/consultation-attendance"),
    visionEvolution: new API("vision-evolution"),
    tensionEvolution: new API("tension-evolution"),
    checkTurn: new TurnAPI("turns/check-turn"),
    generalData: new ReportsAPI("general-data-report"),
    medicationReport: new ReportsAPI("medication-report"),
    studyReport: new ReportsAPI("study-report"),
    surgeryReport: new ReportsAPI("surgery-report"),
    locations: new API("locations"),
    users: new UserAPI("users"),
    reset_password: new PasswordAPI("reset-password"),
    medicalOffices: new API("medical-offices"),
    turns: new API("turns"),
    notices: new API("notices"),
    images: new API("images"),
};

export default api;
export { errorCodes, API, UserAPI };
