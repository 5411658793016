import React, { useEffect, useState } from "react";
import api from "../../api";
import Modal from "../Modal";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const CallerStep2 = ({
    sendJsonMessage,
    userData,
    lastJsonMessage,
    selectedMedicalOffice,
    setStep,
    consultationType,
}) => {
    const [currentPatient, setCurrentPatient] = useState(null);
    const [totalPatients, setTotalPatients] = useState(null);
    const [open, setOpen] = useState(false);
    let didInit = false;

    const getPatientWs = () => {
        if (consultationType === "general") {
            sendJsonMessage(
                {
                    action: "get_general_queue_patient",
                    data: {
                        doctor_id: userData.id,
                        medical_office_id: selectedMedicalOffice,
                    },
                },
                false
            );
        } else {
            sendJsonMessage(
                {
                    action: "get_patient",
                    data: {
                        doctor_id: userData.id,
                    },
                },
                false
            );
        }
    };

    useEffect(() => {
        if (!didInit) {
            didInit = true;
            getPatientWs();
        }
        return () => {
            sendJsonMessage(
                {
                    action: "logout",
                    data: {
                        doctor_id: userData.id,
                    },
                },
                false
            );
        };
    }, []);

    useEffect(() => {
        if (consultationType === "general") {
            sendJsonMessage(
                {
                    action: "get_total_patients",
                    data: {
                        doctor_id: null,
                    },
                },
                false
            );
        } else {
            sendJsonMessage(
                {
                    action: "get_total_patients",
                    data: {
                        doctor_id: userData.id,
                    },
                },
                false
            );
        }
    }, [currentPatient]);

    const getNextPatient = () => {
        getPatientWs();
    };

    const callPatient = () => {
        sendJsonMessage(
            {
                action: "call_patient",
                data: {
                    doctor_id: userData.id,
                    medical_office_id: selectedMedicalOffice,
                    patient_id: currentPatient.patient_id,
                },
            },
            false
        );
    };

    const skipPatient = () => {
        sendJsonMessage(
            {
                action: "mark_missed_appointment",
                data: {
                    turn_id: currentPatient.turn_id,
                },
            },
            false
        );
        setOpen(false);
    };

    useEffect(() => {
        if (
            lastJsonMessage.type === "patient" &&
            lastJsonMessage.status === "success" &&
            lastJsonMessage.message === "Turno marcado como no acudido."
        ) {
            getNextPatient();
        } else if (
            lastJsonMessage.type === "patient" &&
            lastJsonMessage.status === "success"
        ) {
            setCurrentPatient(lastJsonMessage.next_patient);
        } else if (
            lastJsonMessage.type === "total_patients" &&
            lastJsonMessage.status === "success"
        ) {
            setTotalPatients(lastJsonMessage.data.total_patients);
        } else if (
            lastJsonMessage.type === "no_patient" &&
            lastJsonMessage.status === "pass"
        ) {
            setStep(3);
        }
    }, [lastJsonMessage]);

    const confirmation = (
        <>
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon
                        className="h-6 w-6 text-yellow-800"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                    >
                        Confirmar
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres marcar como ausente a
                            este paciente?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={skipPatient}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    );

    return (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Modal
                size="sm:max-w-lg w-full"
                content={confirmation}
                open={open}
                setOpen={setOpen}
            />

            {currentPatient !== null ? (
                <div>
                    <div className="mb-60">
                        <p className="font-bold text-[25px] text-[#75C9EE] leading-[36px] text-center">
                            PACIENTE
                        </p>
                        <p className="font-bold text-[25px] text-[#363636] leading-[36px] text-center mb-5">
                            {currentPatient.patient_full_name}
                        </p>
                        <Link
                            to={`/patients/${currentPatient.patient_id}`}
                            target="_blank"
                            className="block text-[#75C9EE] text-[18px] text-center underline decoration-solid"
                            rel="noopener noreferrer"
                        >
                            Ir al perfil del paciente
                        </Link>
                    </div>
                    <div className="mb-5">
                        <button
                            onClick={callPatient}
                            className="col-span-2 w-1/2 mx-auto flex justify-center py-2 px-4 border border-[#75C9EE] rounded-md shadow-sm text-sm font-medium text-[#75C9EE] mb-3 active:bg-white"
                        >
                            Llamar paciente
                        </button>
                        <button
                            onClick={() => setOpen(true)}
                            className="col-span-2 w-1/2 mx-auto flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-white mb-3 bg-red"
                        >
                            Marcar como ausente
                        </button>
                        <button
                            onClick={() => getNextPatient()}
                            className="col-span-2 w-1/2 mx-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary active:bg-[#00AAF4]"
                        >
                            Siguiente
                        </button>
                    </div>
                    <p className="text-[#363636] text-[14px] text-center">
                        Hay{" "}
                        <span className="font-bold text-[#75C9EE]">
                            {totalPatients}{" "}
                            {totalPatients === 1 ? "paciente" : "pacientes"}
                        </span>{" "}
                        más en tu lista que están esperando ser atendidos.
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default CallerStep2;
