import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Modal from "../Modal";
import { Dialog } from "@headlessui/react";
import BancoLogo from "../../assets/Logo.png";

const CallerList = ({
    sendJsonMessage,
    userData,
    lastJsonMessage,
    selectedMedicalOffice,
    setStep,
    consultationType,
}) => {
    let didInit = false;
    const [patientQueue, setPatientQueue] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [turnToSkip, setTurnToSkip] = useState(null);
    const getPatientQueue = () => {
        sendJsonMessage(
            {
                action: "get_queue",
                data: {
                    doctor_id: userData.id,
                },
            },
            false
        );
    };

    const callPatient = (patientId) => {
        sendJsonMessage(
            {
                action: "call_patient",
                data: {
                    doctor_id: userData.id,
                    medical_office_id: selectedMedicalOffice,
                    patient_id: patientId,
                },
            },
            false
        );
    };

    const skipPatient = () => {
        sendJsonMessage(
            {
                action: "mark_missed_appointment",
                data: {
                    turn_id: turnToSkip.id,
                },
            },
            false
        );
        setOpen(false);
    };

    const removeFromQueue = (turnId) => {
        setPatientQueue(patientQueue.filter((turn) => turn.id !== turnId));
    };

    useEffect(() => {
        if (!didInit) {
            didInit = true;
            getPatientQueue();
        }

        return () => {
            sendJsonMessage(
                {
                    action: "logout",
                    data: {
                        doctor_id: userData.id,
                    },
                },
                false
            );
        };
    }, []);

    useEffect(() => {
        if (
            lastJsonMessage.type === "no_patient" &&
            lastJsonMessage.status === "pass"
        ) {
            setStep(3);
        } else if (
            lastJsonMessage.type === "queue" &&
            lastJsonMessage.status === "success" &&
            lastJsonMessage.message === "Nuevo turno creado."
        ) {
            setPatientQueue([...patientQueue, lastJsonMessage.data]);
        } else if (
            lastJsonMessage.type === "queue" &&
            lastJsonMessage.status === "success"
        ) {
            setPatientQueue(
                lastJsonMessage.data.patients.map((patient) => {
                    return { ...patient, ready: false };
                })
            );
            setLoading(false);
        }
    }, [lastJsonMessage]);

    const confirmation = (
        <>
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon
                        className="h-6 w-6 text-yellow-800"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                    >
                        Confirmar
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres marcar como ausente a
                            este paciente?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => {
                        skipPatient();
                        removeFromQueue(turnToSkip.id);
                    }}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                        setTurnToSkip(null);
                        setOpen(false);
                    }}
                >
                    Cancelar
                </button>
            </div>
        </>
    );

    return (
        <div className="-my-2 sm:-mx-6 lg:-mx-8 w-full self-start mt-8">
            {loading ? (
                <div className="h-screen flex items-center justify-center">
                    <div className="w-10 h-10 mx-5 border-b-2 border-primary rounded-full animate-spin"></div>
                </div>
            ) : (
                <div>
                    {patientQueue.length > 0 ? (
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <h1 class="text-2xl font-bold leading-9 text-gray-600 mb-8">
                                Gestión de turnos de consultas
                            </h1>

                            <div className={`shadow border-b border-gray-200`}>
                                <Modal
                                    size="sm:max-w-lg w-full"
                                    content={confirmation}
                                    open={open}
                                    setOpen={setOpen}
                                />

                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 z-30 shadow-sm">
                                        <tr>
                                            <th
                                                scope="col"
                                                className=" py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Turno
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Paciente
                                            </th>
                                            <th
                                                scope="col"
                                                className=" py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Edad
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                CI
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Descripción
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            ></th>

                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {patientQueue.map((el) => (
                                            <tr
                                                key={el.gov_id}
                                                style={
                                                    el.ready
                                                        ? {
                                                              backgroundColor:
                                                                  "#9CEC5B",
                                                          }
                                                        : {}
                                                }
                                            >
                                                <td className="whitespace-nowrap text-center text-sm font-medium">
                                                    #{el.position}
                                                </td>
                                                <td className="px-6 whitespace-nowrap text-left text-sm font-medium text-primary hover:text-primary">
                                                    <Link
                                                        to={`/patients/${el.patient_id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {el.patient}
                                                    </Link>
                                                </td>
                                                <td className=" whitespace-nowrap text-center text-sm font-medium">
                                                    <div className="text-gray-900">
                                                        {el.age < 1
                                                            ? "RN"
                                                            : el.age}
                                                    </div>
                                                </td>
                                                <td className=" whitespace-nowrap text-right text-sm font-medium">
                                                    <div className="text-gray-900">
                                                        {el.gov_id}
                                                    </div>
                                                </td>
                                                <td className=" whitespace-nowrap text-right text-sm font-medium">
                                                    <div className="text-gray-900">
                                                        {el.service_description.join(
                                                            ", "
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-6 whitespace-nowrap text-center text-sm font-medium">
                                                    {el.is_urgent ? (
                                                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                                            Urgente
                                                        </span>
                                                    ) : null}
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                                                    {!el.ready ? (
                                                        <div className="flex gap-x-2 justify-center">
                                                            <button
                                                                onClick={() =>
                                                                    callPatient(
                                                                        el.patient_id
                                                                    )
                                                                }
                                                                className="w-1/3  flex justify-center py-2 px-4 border border-[#75C9EE] rounded-md shadow-sm text-sm font-medium text-[#75C9EE] active:bg-blue-100"
                                                            >
                                                                Llamar
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    setTurnToSkip(
                                                                        el
                                                                    );
                                                                    setOpen(
                                                                        true
                                                                    );
                                                                }}
                                                                className="w-1/3  flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-white  bg-red active:bg-rose-400"
                                                            >
                                                                Ausente
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    setPatientQueue(
                                                                        patientQueue.map(
                                                                            (
                                                                                patient
                                                                            ) => {
                                                                                if (
                                                                                    patient.gov_id ===
                                                                                    el.gov_id
                                                                                ) {
                                                                                    return {
                                                                                        ...patient,
                                                                                        ready: true,
                                                                                    };
                                                                                } else {
                                                                                    return {
                                                                                        ...patient,
                                                                                    };
                                                                                }
                                                                            }
                                                                        )
                                                                    )
                                                                }
                                                                className="w-1/3  flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-teal-600  border-teal-600 active:bg-teal-100"
                                                            >
                                                                Finalizar
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="w-48 mx-auto"
                                src={BancoLogo}
                                alt="Banco de Ojos"
                            />
                            <h2 className="text-center text-[30px] font-bold leading-9 text-primary mb-10">
                                Ya no quedan pacientes por atender
                            </h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default CallerList;
