import {
    Routes,
    Route,
    BrowserRouter as Router,
    Navigate,
} from "react-router-dom";
import { useAuth } from "./context/authContext";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import PatientNewPage from "./pages/patients/PatientNewPage";
import PatientPage from "./pages/patients/PatientPage";
import UserNewPage from "./pages/users/UserNewPage";
import UserPage from "./pages/users/UserPage";
import PatientDetailPage from "./pages/patients/PatientDetailPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ConsultationNewPage from "./pages/patients/ConsultationNewPage";
import PatientDocumentsPage from "./pages/patients/PatientDocumentsPage";
import PatientCallerPage from "./pages/caller/PatientCallerPage";
import WaitingRoomPage from "./pages/WaitingRoomPage";
import ConsultationHistoryPage from "./pages/patients/ConsultationHistory";
import CheckTurnPage from "./pages/CheckTurnPage";
import GeneralDataPage from "./pages/reports/GeneralDataPage";
import SurgeriesPage from "./pages/reports/SurgeriesPage";
import StudiesPage from "./pages/reports/StudiesPage";
import MedicationPage from "./pages/reports/MedicationPage";
import TurnsPage from "./pages/TurnsPage";
import NoticesPage from "./pages/NoticesPage";
import BackgroundImagePage from "./pages/BackgroundImagePage";

function App() {
    const auth = useAuth();

    const authenticatedRoutes = (
        <Routes>
            <Route path="/" element={<Navigate to="/patients" />} />
            <Route exact path="/patients" element={<PatientPage />} />
            <Route exact path="/patients/new" element={<PatientNewPage />} />
            <Route
                path="/patients/:idPatient"
                element={<PatientDetailPage />}
            />
            <Route
                path="/patients/:idPatient/edit"
                element={<PatientNewPage />}
            />
            <Route
                path="/patients/:idPatient/new"
                element={<ConsultationNewPage />}
            />
            <Route
                path="/patients/:idPatient/documents"
                element={<PatientDocumentsPage />}
            />
            <Route
                path="/patients/:idPatient/history"
                element={<ConsultationHistoryPage />}
            />
            <Route
                path="/patients/:idPatient/history"
                element={<ConsultationHistoryPage />}
            />
            <Route
                path="/patients/:idPatient/consultations/:idConsultation"
                element={<ConsultationNewPage />}
            />
            <Route exact path="/users" element={<UserPage />} />
            <Route exact path="/users/new" element={<UserNewPage />} />
            <Route path="/users/:idUser" element={<UserNewPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/turns" element={<PatientCallerPage />} />
            <Route path="/turns-list" element={<TurnsPage />} />
            <Route path="/reports/general-data-report" element={<GeneralDataPage />} />
            <Route path="/reports/surgery-report" element={<SurgeriesPage />} />
            <Route path="/reports/study-report" element={<StudiesPage />} />
            <Route path="/reports/medication-report" element={<MedicationPage />} />
            <Route path="/check-turn" element={<CheckTurnPage />} />
            <Route path="/lobby" element={<WaitingRoomPage />} />
            <Route path="/notice" element={<NoticesPage />} />
            <Route path="/image" element={<BackgroundImagePage />} />
        </Routes>
    );

    return (
        <Router>
            {auth.isAuthenticated ? (
                authenticatedRoutes
            ) : (
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/reset-password/:uuid/:token"
                        element={<ResetPasswordPage />}
                    />
                    <Route path="/lobby" element={<WaitingRoomPage />} />
                    <Route path="/check-turn" element={<CheckTurnPage />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </Router>
    );
}

export default App;
