import React, { useState } from "react";
import SelectInput from "../../components/SelectInput";
import BancoLogo from "../../assets/Logo.png";
const CallerStep1 = ({
    userData,
    selectedMedicalOffice,
    handleChange,
    medicalOffices,
    loading,
    connectQueue,
    setConsultationType,
}) => {
    const consultationTypes = [
        { id: "general", title: "Consulta General" },
        { id: "especialidad", title: "Consulta de especialidad" },
    ];

    return (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="w-48 mx-auto" src={BancoLogo} alt="Banco de Ojos" />
            <h2 className="text-center text-[30px] font-bold leading-9 text-primary mb-10">
                {`Hola Dr${userData.sex === 1 ? "" : "a"}. ${
                    userData.firstName
                } ${userData.lastName},`}{" "}
                <br />
                ¿Listo para empezar a atender?
            </h2>

            <SelectInput
                // errorMessage={error?.specialty}
                value={selectedMedicalOffice}
                placeholder={true}
                handleChange={handleChange}
                name="medicalOffice"
                label="Seleccioná en qué consultorio te encontrás"
                className="shadow-sm pl-2 w-full bg-white border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md mb-10"
                options={medicalOffices.map((element) => (
                    <option
                        key={element.id}
                        value={element.id}
                        label={element.name}
                    >
                        {element.name}
                    </option>
                ))}
            />
            <div className="mb-10">
                <p className="text-sm font-medium text-gray-700">
                    Seleccioná el tipo de consulta
                </p>
                <fieldset
                    className="mt-4"
                    onChange={(e) => setConsultationType(e.target.id)}
                >
                    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        {consultationTypes.map((el) => (
                            <div key={el.id} className="flex items-center">
                                <input
                                    id={el.id}
                                    name="notification-method"
                                    type="radio"
                                    defaultChecked={el.id === "general"}
                                    className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                />
                                <label
                                    htmlFor={el.id}
                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                >
                                    {el.title}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </div>
            <div>
                <button
                    type="submit"
                    onClick={connectQueue}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary    "
                >
                    {loading ? (
                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div>
                    ) : (
                        "Empezar a atender"
                    )}
                </button>
            </div>
        </div>
    );
};

export default CallerStep1;
