import React, { useEffect } from "react";
import { useState } from 'react'
import api from "../api";
import { useParams, useNavigate } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import RadioButtons from "../components/RadioButtons";
import FileInput from "../components/FileInput";
import InlineCheckbox from "../components/InlineCheckbox";
import TextArea from "../components/TextArea";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BackgroundImagePage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(undefined)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();
    const [error, setError] = useState({});

    const handleChange = (field, value) => setImage((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (event) => {
        event.preventDefault()
        setSuccess(false);
        setLoading(true);
        setError({});
        let formData = new FormData();
        for (var key in image) {
            if (image[key]) {
                formData.append(key, image[key]);
            }
        }
        api.images.update(1, formData).then((response) => (setSuccess(true), setLoading(false))).catch((error) => (setError(error), setLoading(false)))
    };

    useEffect(() => {
        api.images.getOne(1).then((response) => setImage(response)).catch((error) => setError(error))
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Imágenes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1 bg-gray-100 pb-6">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <div className="flex flex-col flex-1 gap-6 mt-6 divide-gray-200 px-10 w-2/3">
                            <p className="text-xl font-semibold">Imágenes</p>
                            {image?.image && <img alt='bgImage' src={typeof image?.image === 'string' ? image?.image : URL.createObjectURL(image?.image)} height={256} width={256} />}
                            <FileInput value={image?.image} name='image' handleChange={handleChange} />
                            <InlineCheckbox value={image?.show} name='show' label='Mostrar imagen de fondo' handleChange={handleChange} />
                            {success &&
                                <div className="bg-white border border-gray-300 mt-4 w-fit py-2 px-4 rounded-xl">
                                    Sus cambios han sido guardados exitosamente.
                                </div>
                            }
                            <button type="submit" className="col-span-2 w-1/2 mx-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                {loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> : 'Guardar cambios'}
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}
