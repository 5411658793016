import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Checkbox(props) {

    return (
        <div className={props.containerClassName ? props.containerClassName : "relative flex flex-col gap-2 -mt-2"}>
            <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "text-sm whitespace-wrap w-fit whitespace-nowrap font-medium text-gray-700"}>
                {props.label}
            </label>
            <input
                checked={props.value}
                onChange={(event) => props.Idx !== undefined ? props.handleChange(props.name, event.target.checked, props.Idx) : props.handleChange(props.name, event.target.checked)}
                id={props.name}
                aria-describedby={`${props.name}-description`}
                name={props.name}
                type="checkbox"
                value={props.value}
                className={props.className ? props.className : "focus:ring-primary self-center mt-1 h-4 w-4 text-primary border-gray-300 rounded"}
            />
        </div>
    )

}