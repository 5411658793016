import React, { useEffect } from "react";
import { useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
    PlusIcon,
    MapPinIcon
} from '@heroicons/react/24/outline'
import { NoSymbolIcon } from "@heroicons/react/24/solid";
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Table from "../../components/Table";

const roles = [
    'Dr. Residente',
    'Secretaría',
    'Dr. Senior',
    'Administrador',
    'Licenciado',
]

const columns = [
    { name: 'name', displayText: 'Nombre y Apellido', clickable: true },
    { name: 'role', displayText: 'Rol' },
    { name: 'govId', displayText: 'Cédula' },
    { name: 'email', displayText: 'Correo' },
    { name: 'specialty', displayText: 'Especialidad' },
    { name: 'registrationNumber', displayText: 'Reg. Prof N°' },
    { name: 'signature', displayText: 'Firma' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.specialties.getMulti().then((specialties) => {
            api.users.getMulti(null, search, null, null, null).then((response) => {
                setUsers(response.filter((array) => { return array.firstName ? true : false }).map((element) => {
                    return ({ ...element, name: element.firstName + ' ' + element.lastName, role: roles[element.role - 1], signature: element.digitalSignature ? 'SI' : 'NO', specialty: element.specialty ? specialties.find((specialty) => specialty.id === (element.specialty)).name : '' })
                }))
                setLoading(false)
            }
            )
        })
    }, [search])


    return (
        <div className="bg-gray-100">
            <Sidebar currentScreen={'Usuarios'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div> :
                    <>
                        <Header setSidebarOpen={setSidebarOpen} search={true} setSearch={setSearch} searchPlaceholder="Buscar por nombre o número de documento de identidad" buttonText={!users.length ? null : "Nuevo usuario"} href="/users/new" />
                        <main className="flex-1 bg-gray-100">
                            {users.length ?
                                <div className="py-6">
                                    <div className="px-4 sm:px-6 md:px-8">
                                        <Table module="users" columns={columns} rows={users} actiontext="Editar" />
                                    </div>
                                </div>
                                :
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <NoSymbolIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                        <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o ingresá un nuevo usuario.</p>
                                        <div className="mt-6">
                                            <Link
                                                to="/users/new"
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-primary hover:bg-primary"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Nuevo usuario
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </main>
                    </>
                }
            </div>
        </div >
    );
}
