import React, { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AddInput(props) {

    const [value, setValue] = useState('');
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (value !== '') {
                props.handleChange(props.name, value);
                setValue('');
                e.stopPropagation();
                e.preventDefault();
            }
        } else if (props.onlyNumbers) {
            if (!/[\d]|Backspace|ArrowLeft|ArrowRight/.test(e.key))
                e.preventDefault();
        }

    };

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="text-sm font-medium text-gray-700">
                {props.label}
                {props.required ? <p className="font-medium text-[#F51515] inline-block ml-2"> *</p> : null}
            </label>
            <div className="mt-1 relative rounded-md flex shadow-sm w-full border focus-within:ring-blue-500 focus-within:border-blue-500 border-gray-300 sm:text-sm">
                <input
                    onChange={(event) => setValue(event.target.value)}
                    type='text'
                    name={props.name}
                    id={props.name}
                    value={value}
                    placeholder={props.placeholder}
                    className={props.className ? props.className : "shadow-sm w-full pl-2 border-0 block sm:text-sm rounded-md outline-none focus:outline-none focus-visible:outline-none"}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    onKeyDown={handleKeyDown}
                    aria-describedby={`${props.name}-description`}
                />
                <PlusIcon onClick={value !== '' ? () => (props.handleChange(props.name, value), setValue('')) : null} className="h-4 w-4 cursor-pointer mt-2.5 text-gray-500 mr-2" aria-hidden="true" />
            </div>
            {props?.value?.length > 0 &&
                <div className="mt-2">
                    {props.value.map((element, idx) => (
                        <div className="flex gap-x-3">
                            <p>{props.unit} {idx + 1}: {typeof element === 'object' ? element.number : element}</p>
                            <XMarkIcon onClick={() => props.handleDelete(idx)} className="h-4 w-4 mt-1.5 text-gray cursor-pointer" aria-hidden="true" />
                        </div>
                    ))}
                </div>
            }
            {props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div >
    )

}