import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom'
import BancoLogo from '../assets/Logo.png';
import { useAuth } from '../context/authContext'
import Modal from '../components/Modal';
import { Dialog } from "@headlessui/react";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import api from "../api";

export default function LoginPage() {

    const [email, setEmail] = useState([])
    const [password, setPassword] = useState([])
    const [openForgotPassword, setOpenForgotPassword] = useState(false)
    const [error_message, setErrorMessage] = useState("")

    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate();
    const auth = useAuth();

    const recoverPassword = async () => {
        await api.reset_password.sendEmail(email)
            .then((response) => {
                console.log(response)
                setOpenForgotPassword(false)
            })
            .catch((error) => console.log(error))
    }

    const forgotPassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Recuperar Contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Correo Electrónico
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                            <input
                                type="email"
                                name="email"
                                onChange={(event) => setEmail(event.target.value)}
                                id="email"
                                className="block mt-1 w-full h-8 pr-10 sm:text-sm border pl-1 border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        recoverPassword()
                    }
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenForgotPassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const handleSubmit = async (event) => {
        event.preventDefault()
        setErrorMessage('')
        setLoading(true)
        auth
            .signIn(email, password)
            .then((response) => {
                console.log(response)
                setLoading(false)
                if (response.userData.role === 1 || response.userData.role === 3)
                    navigate('/turns');
                else
                    navigate('/patients')
            })
            .catch((e) => {
                setLoading(false)
                setErrorMessage(e.detail)
            });
    }

    return (
        <>
            <div className="min-h-screen h-full flex flex-col justify-center py-4 sm:px-6 bg-gray-50 lg:px-8">
                <Modal size='sm:max-w-lg w-full' content={forgotPassword} open={openForgotPassword} setOpen={setOpenForgotPassword} />
                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="h-auto w-48 mx-auto"
                        src={BancoLogo}
                        alt="Banco de Ojos"
                    />
                    <h2 className="mt-8 text-center text-[30px] font-bold leading-9 text-primary">Inicio de sesión</h2>
                </div>

                <div className="mt-8 pb-4 mb-4 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Correo electrónico
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={(event) => setEmail(event.target.value)}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Contraseña
                                </label>
                                <div className="flex mt-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(event) => setPassword(event.target.value)}
                                        required
                                        className="appearance-none rounded-l-md flex flex-1 px-3 py-2 outline-none border-none"
                                    />
                                    <button
                                        onClick={() => setShowPassword(!showPassword)}
                                        type="button"
                                        className="px-3 bg-none text-primary hover:text-primary"
                                    >
                                        {showPassword ?
                                            <EyeSlashIcon className="h-5 w-5" aria-hidden="true" /> :
                                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                        }
                                    </button>
                                </div>
                                {error_message === "" ?
                                    null
                                    : <div className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                        <span className="block sm:inline">Credenciales inválidas.</span>
                                    </div>
                                }
                            </div>

                            <div className="flex justify-center">
                                <div className="text-sm">
                                    <button type="button" onClick={() => setOpenForgotPassword(true)} className="font-medium mx-auto text-primary">
                                        ¿Olvidaste tu contraseña?
                                    </button>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary    "
                                >
                                    {loading ?
                                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                        'Iniciar sesión'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >

        </>
    );
}
