import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition, Dialog } from '@headlessui/react'
import {
    Bars3Icon,
    PlusIcon
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

export default function Header(props) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [value, setValue] = useState(props.value)
    const handleKeyDown = (e) => {
        if (e.code === "Enter") {
            props.setSearch(e.target.value);
        }
    };

    return (

        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
                type="button"
                className="px-2 xs:px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
                onClick={() => props.setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 flex w-full items-center px-2 justify-start">
                {props.search ?
                    <div className="w-full ml-2 sm:ml-8 lg:ml-16">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="relative">
                            <div className="absolute w-full inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MagnifyingGlassIcon className="h-5 w-5 text-blue-600" aria-hidden="true" />
                            </div>
                            <input
                                id="search"
                                name="search"
                                onKeyDown={(event) => (handleKeyDown(event))}
                                onChange={(event) => setValue(event.target.value)}
                                value={value}
                                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                                placeholder={props.searchPlaceholder}
                                type="search"
                            />
                        </div>
                    </div>
                    : null
                }
            </div>
            <div className="flex-1 flex items-center px-2 justify-end">
                {props.buttonText ? <Link
                    to={props.href}
                    className="inline-flex mr-2 sm:mr-8 lg:mr-16 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-primary hover:bg-primary"
                >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {props.buttonText}
                </Link>
                    : null}
            </div>
        </div>
    );
}
