import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextInput(props) {

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className={classNames(props.labelClassName, "text-sm font-medium text-gray-700")}>
                {props.label}
                {props.required ? <p className="font-medium text-[#F51515] inline-block ml-2"> *</p> : null}
            </label>
            <div className="mt-1 relative rounded-md flex w-full">
                <input
                    onChange={(event) => props.Idx != null ? props.handleChange(props.name, (event.target.value && props.parse) ? parseInt(event.target.value) : event.target.value, props.Idx) : props.handleChange(props.name, props.parse ? parseInt(event.target.value) : event.target.value)}
                    type={props.type}
                    name={props.name}
                    onKeyDown={props.onKeyDown}
                    id={props.name}
                    pattern={props.pattern}
                    value={props.value}
                    min={props.min}
                    max={props.max}
                    placeholder={props.placeholder}
                    className={classNames(props.errorMessage ? 'border border-red-500' : 'border border-gray-300', props.className ? props.className : "shadow-sm pl-2 w-full block sm:text-sm rounded-md")}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    aria-describedby={`${props.name}-description`}
                />
            </div>
            {props.description && <p className="mt-2 text-xs text-center text-gray-500" id={`${props.name}-description`}>
                {props.description}
            </p>}
            {(props.errorMessage && props.showErrorMessage) && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div >
    )

}