import React, { useEffect, useState } from "react";
import InlineCheckbox from "./InlineCheckbox";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import RadioButtons from "./RadioButtons";
import DateInput from "./DateInput";
import AddInput from "./AddInput";
import SimpleCombobox from "./SimpleCombobox";

const MedicalHistory = function (props) {

    const goBack = () => {
        props.setTab('DATOS PERSONALES')
    }

    return (
        <form onSubmit={(event) => props.handleSubmit(event)}>
            <div className="flex-col space-y-4 py-5 sm:p-6">
                <div>
                    <label className="font-semibold text-gray-700">Antecedente personal</label>
                </div>
                <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                    <InlineCheckbox value={props.patient?.dm} handleChange={props.handleChange} name="dm" label="DM" />
                    <InlineCheckbox value={props.patient?.hta} handleChange={props.handleChange} name="hta" label="HTA" />
                    <InlineCheckbox value={props.patient?.gl} handleChange={props.handleChange} name="gl" label="Glaucoma" />
                </div>
                <div>
                    <label className="font-semibold text-gray-700">Antecedente ocular</label>
                </div>
                <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                    <InlineCheckbox value={props.patient?.strabismus} handleChange={props.handleChange} name="strabismus" label="Estrabismo" />
                    <InlineCheckbox value={props.patient?.glaucoma} handleChange={props.handleChange} name="glaucoma" label="Glaucoma" />
                </div>
                <div>
                    <label className="font-semibold text-gray-700">Cirugías oftalmológicas</label>
                </div>
                <div className="grid grid-cols-3 px-4 gap-y-4 w-2/3">
                    <InlineCheckbox value={props.patient?.cataractSurgery} handleChange={props.handleChange} name="cataractSurgery" label="Cataratas" />
                    <InlineCheckbox value={props.patient?.glaucomaSurgery} handleChange={props.handleChange} name="glaucomaSurgery" label="Glaucoma" />
                    <InlineCheckbox value={props.patient?.refractiveSurgery} handleChange={props.handleChange} name="refractiveSurgery" label="Refractiva" />
                </div>
                <div className="mt-10">
                    <label className="font-semibold text-gray-700">Antecedentes familiares</label>
                </div>
                <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2 -ml-2">
                    <label className="invisible">Esquina</label>
                    <label>Diabetes</label>
                    <label>HTA</label>
                    <label className="-ml-10">Glaucoma</label>
                </div>
                <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                    <label className="font-medium text-sm">Padre</label>
                    <InlineCheckbox value={props.patient?.fatherDm} handleChange={props.handleChange} name="fatherDm" />
                    <InlineCheckbox value={props.patient?.fatherHta} handleChange={props.handleChange} name="fatherHta" />
                    <InlineCheckbox value={props.patient?.fatherGlaucoma} handleChange={props.handleChange} name="fatherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                </div>
                <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                    <label className="font-medium text-sm">Madre</label>
                    <InlineCheckbox value={props.patient?.motherDm} handleChange={props.handleChange} name="motherDm" />
                    <InlineCheckbox value={props.patient?.motherHta} handleChange={props.handleChange} name="motherHta" />
                    <InlineCheckbox value={props.patient?.motherGlaucoma} handleChange={props.handleChange} name="motherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                </div>
                <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                    <label className="font-medium text-sm">Hermanos</label>
                    <InlineCheckbox value={props.patient?.siblingsDm} handleChange={props.handleChange} name="siblingsDm" />
                    <InlineCheckbox value={props.patient?.siblingsHta} handleChange={props.handleChange} name="siblingsHta" />
                    <InlineCheckbox value={props.patient?.siblingsGlaucoma} handleChange={props.handleChange} name="siblingsGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                </div>
                <div className="grid grid-cols-4 font-medium text-gray-700 w-1/2">
                    <label className="font-medium text-sm">Otros</label>
                    <InlineCheckbox value={props.patient?.otherDm} handleChange={props.handleChange} name="otherDm" />
                    <InlineCheckbox value={props.patient?.otherHta} handleChange={props.handleChange} name="otherHta" />
                    <InlineCheckbox value={props.patient?.otherGlaucoma} handleChange={props.handleChange} name="otherGlaucoma" className="-mx-5 focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                </div>
            </div>
            <div className="flex-col-reverse bg-gray-100" >
                <div className="flex p-4 justify-between items-start">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-primary hover:text-primary"
                        onClick={() => goBack()}
                    >
                        Anterior
                    </button>
                    <div className="flex flex-col items-end">
                        <div>
                            <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            >
                                {props.loadingSubmit ?
                                    <div className="w-5 h-5 mx-14 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Finalizar y guardar'
                                }
                            </button>
                        </div>
                        {props.submitError && <div className="mt-2 ml-2 text-sm text-red-600">
                            {props.submitError}
                        </div>}
                    </div>

                </div>
            </div>
        </form>
    )
}

const PersonalData = function (props) {

    const today = new Date();
    const minBirthdate = new Date(today.setFullYear(today.getFullYear() - 120));
    const [rucAutofill, setRucAutofill] = useState(true);
    const [legalNameAutofill, setLegalNameAutofill] = useState(true);

    const goForward = (event) => {
        event.preventDefault()
        let error = false
        if (!props.patient?.firstName) {
            props.handleError('firstName', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('firstName', '')
        }
        if (!props.patient?.lastName) {
            props.handleError('lastName', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('lastName', '')
        }
        if (!props.patient?.sex) {
            props.handleError('sex', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('sex', '')
        }
        if (!props.patient?.city) {
            props.handleError('city', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('city', '')
        }
        if (!props.patient?.govId) {
            props.handleError('govId', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('govId', '')
        }
        if (!props.patient?.birthdate) {
            props.handleError('birthdate', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('birthdate', '')
        }
        if (!props.patient?.phoneNumbers || props.patient?.phoneNumbers.length === 0) {
            props.handleError('phoneNumbers', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('phoneNumbers', '')
        }
        if (!error) {
            props.setTab('ANTECEDENTES')
        }
    }

    useEffect(() => {
        if (legalNameAutofill)
            props.handleChange('legalName', `${props.patient?.firstName ? props.patient?.firstName : ''} ${props.patient?.lastName ? props.patient?.lastName : ''}`)
    }, [props.patient?.firstName])

    useEffect(() => {
        if (legalNameAutofill)
            props.handleChange('legalName', `${props.patient?.firstName ? props.patient?.firstName : ''} ${props.patient?.lastName ? props.patient?.lastName : ''}`)
    }, [props.patient?.lastName])

    useEffect(() => {
        if (rucAutofill)
            props.handleChange('ruc', props.patient?.govId)
    }, [props.patient?.govId])

    const handleRuc = (field, value) => {
        setRucAutofill(false);
        props.handleChange(field, value);
    }

    const handleLegalName = (field, value) => {
        setLegalNameAutofill(false);
        props.handleChange(field, value);
    }

    return (
        <form onSubmit={(event) => goForward(event)}>
            <div className="flex-col py-5 sm:p-6 w-4/5">
                <div className="grid grid-cols-3 gap-y-5 gap-x-6">
                    <TextInput showErrorMessage={true} required={true} errorMessage={props.errors?.firstName} value={props.patient?.firstName} handleChange={props.handleChange} name="firstName" label="Nombre" className="shadow-sm pl-2 w-full border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" />
                    <TextInput showErrorMessage={true} required={true} errorMessage={props.errors?.lastName} value={props.patient?.lastName} handleChange={props.handleChange} name="lastName" label="Apellido" className="shadow-sm pl-2 w-full border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" />
                    <div />
                    <RadioButtons required={true} errorMessage={props.errors?.sex} defaultValue={props.patient?.sex} value={props.patient?.sex} handleChange={props.handleChange} name="sex" label="Sexo" options={props.radio} />
                    <DateInput required={true} mindate={minBirthdate.toISOString().substring(0, 10)} maxdate={new Date().toISOString().substring(0, 10)} errorMessage={props.errors?.birthdate} defaultValue={props.patient?.birthdate ? props.patient?.birthdate : null} handleChange={props.handleChange} name="birthdate" label="Fecha de nacimiento" />
                    <div>
                        <label className="text-sm font-medium text-gray-700">
                            Edad
                        </label>
                        <p className="mt-2">{props.patient?.age}</p>
                    </div>
                    <TextInput showErrorMessage={true} required={true} errorMessage={props.errors?.govId} value={props.patient?.govId} handleChange={props.handleChange} name='govId' label="Cédula de identidad" className="shadow-sm pl-2 w-full border border-gray-300 block sm:text-sm rounded-md" />
                    <SimpleCombobox inputClassName='relative mt-1' required={true} error={props.errors?.city} value={props.patient?.city} handleChange={props.handleChange} name="city" label="Ciudad" className="shadow-sm pl-2 mt-1 w-full bg-white border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" options={props?.cities} />
                    <div />
                    <TextInput showErrorMessage={true} errorMessage={props.errors?.ruc} value={props.patient?.ruc} handleChange={handleRuc} name="ruc" label="RUC" className="shadow-sm pl-2 w-full border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" />
                    <TextInput showErrorMessage={true} errorMessage={props.errors?.legalName} value={props.patient?.legalName} handleChange={handleLegalName} name="legalName" label="Razón social" className="shadow-sm pl-2 w-full border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" />
                    <div />
                    <AddInput onlyNumbers={true} handleDelete={props.removePhone} required={true} placeholder='0980 000 000' errorMessage={props.errors?.phoneNumbers} unit='Numero' value={props.patient?.phoneNumbers} handleChange={props.handlePhones} name="phoneNumbers" label="Celular / Teléfono" className="shadow-sm w-full pl-2 border-0 block sm:text-sm rounded-md focus:ring-0 focus-visible:outline-none" />
                    <TextInput showErrorMessage={true} errorMessage={props.errors?.email} value={props.patient?.email} handleChange={props.handleChange} name="email" label="Correo electrónico" className="shadow-sm pl-2 w-full border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" />
                </div>
            </div>
            <div className="flex-col-reverse bg-gray-100" >
                <div className="flex p-4 justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </form>
    )
}

export default function FormNewPatient(props) {

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    useEffect(() => {
        if (props.patient?.birthdate == new Date().toISOString().substring(0, 10) || !props.patient?.birthdate)
            props.handleChange("age", "-")
        else {
            props.handleChange("age", getAge(props.patient?.birthdate))
        }
    }, [props.patient?.birthdate])

    return (
        <div>
            {props.tab === 'DATOS PERSONALES' && <PersonalData
                patient={props.patient}
                handleChange={props.handleChange}
                handleError={props.handleError}
                errors={props.error}
                radio={props.radio}
                cities={props.cities}
                options={props.options}
                handlePhones={props.handlePhones}
                removePhone={props.removePhone}
                setTab={props.setTab}
            />
            }
            {props.tab === 'ANTECEDENTES' && <MedicalHistory
                patient={props.patient}
                errors={props.error}
                setTab={props.setTab}
                handleError={props.handleError}
                handleChange={props.handleChange}
                loadingSubmit={props.loadingSubmit}
                handleSubmit={props.handleSubmit}
                submitError={props.submitError}
            />}
        </div>
    )
}