import React from "react";
import { CalendarIcon } from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DateInput(props) {

    return (
        <div className={props.containerStyle ? props.containerStyle : ""}>
            {props.label && <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "text-sm font-medium text-gray-700"}>
                {props.label}
                {props.required ? <p className="font-medium text-[#F51515] inline-block ml-2"> *</p> : null}
            </label>}
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    min={props.min}
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    type="date"
                    value={props.defaultValue ? props.defaultValue : props.value}
                    name={props.name}
                    id={props.name}
                    min={props.mindate}
                    max={props.maxdate}
                    className={props.className ? props.className : "shadow-sm pl-2 w-full border border-gray-300 block sm:text-sm rounded-md"}
                />
            </div>
            <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>
        </div>
    )
}