import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RadioButtons(props) {

    const optionsContStyle = props.optionsContainerStyle ? props.optionsContainerStyle : "space-y-4 sm:flex sm:items-center sm:space-y-0 sm:gap-x-10"

    return (
        <div>
            <label className={props.labelClassName ? props.labelClassName : "text-sm font-medium text-gray-700"}>{props.label}</label>
            <fieldset className={props.label ? "mt-4" : ""}>
                <legend className="sr-only">{props.label}</legend>
                <div className={optionsContStyle}>
                    {props.options.map((option) => {
                        return (<div className="flex items-center" key={option.id}>
                            <input
                                onChange={(event) => props.handleChange(props.name, event.target.value)}
                                id={option.id}
                                name={props.name}
                                type="radio"
                                value={option.value}
                                checked={option.value == props.defaultValue}
                                className={option.className ? option.className : "h-4 w-4 text-primary border-gray-300 focus:ring-none focus-visible:ring-none"}
                            />
                            <label htmlFor={option.label} className={option.labelClassName ? option.labelClassName : "ml-3 block text-sm font-medium text-gray-700"}>
                                {option.label}
                            </label>
                        </div>)
                    })}
                </div>
            </fieldset>
            {props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div>)
}