import React, { useContext, useEffect, useState } from "react";
import {
    CalendarIcon,
    ChartBarIcon,
    LocationMarkerIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline'
import api from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Steps from "../../components/Steps";
import FormNewPatient from "../../components/FormNewPatient";

const radio = [
    { id: "male", default: true, label: "Masculino", value: 1 },
    { id: "female", default: false, label: "Femenino", value: 2 }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientNewPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [cities, setCities] = useState([]);
    const [tab, setTab] = useState('DATOS PERSONALES')
    const navigate = useNavigate();
    const [patient, setPatient] = useState({
        phoneNumbers: [],
        dm: false,
        hta: false,
        aler: false,
        gl: false,
        strabismus: false,
        glaucoma: false,
        cataractSurgery: false,
        glaucomaSurgery: false,
        refractiveSurgery: false,
        motherDm: false,
        motherHta: false,
        motherGlaucoma: false,
        fatherDm: false,
        fatherHta: false,
        fatherGlaucoma: false,
        siblingsDm: false,
        siblingsHta: false,
        siblingsGlaucoma: false,
        otherDm: false,
        otherHta: false,
        otherGlaucoma: false,
        registrationDate: new Date().toISOString().split('T')[0]
    })
    const [error, setError] = useState();
    const { idPatient } = useParams();
    const [steps, setStep] = useState(
        [
            { id: '01', name: 'DATOS PERSONALES', status: 'current' },
            { id: '02', name: 'ANTECEDENTES', status: 'upcoming' },
        ]
    )
    const [pages, setPages] = useState([
        { name: 'Pacientes', href: '/patients', current: false },
        { name: 'Registro del paciente', href: `#`, current: true },
    ])

    useEffect(() => {
        if (tab == 'DATOS PERSONALES') {
            setStep([
                { id: '01', name: 'DATOS PERSONALES', status: 'current' },
                { id: '02', name: 'ANTECEDENTES', status: 'upcoming' },
            ])
        } else {
            setStep([
                { id: '01', name: 'DATOS PERSONALES', status: 'complete' },
                { id: '02', name: 'ANTECEDENTES', status: 'current' },
            ])
        }
    }, [tab])

    const handleChange = (field, value) => setPatient((prev) => ({ ...prev, [field]: value }));
    const handlePhones = (field, value) => {
        let obj = patient.phoneNumbers ? patient.phoneNumbers.slice() : [];
        obj.push({ number: value });
        setPatient((prev) => ({ ...prev, ['phoneNumbers']: obj }));
    };

    const removePhone = (indx) => {
        let auxPhones = patient.phoneNumbers.slice(0)
        auxPhones.splice(indx, 1)
        setPatient((prev) => ({ ...prev, ['phoneNumbers']: auxPhones }));
    }

    const handleError = (field, value) => setError((prev) => ({ ...prev, [field]: value }))

    useEffect(() => {
        api.cities.getMulti().then((response) => {
            setCities(response);
            if (idPatient) {
                api.patients.getOne(idPatient).then((data) => {
                    setPatient({ ...data, city: response.find((element) => element.id === data.city) })
                    setPages([
                        { name: 'Pacientes', href: '/patients', current: false },
                        { name: `${data.firstName} ${data.lastName}`, href: `/patients/${idPatient}`, current: false },
                        { name: 'Registro del paciente', href: `#`, current: true },
                    ])
                    setLoading(false);
                })
            } else {
                handleChange('city', response.find((element) => element.name === 'Asuncion'))
                setLoading(false);
            }
        })
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoadingSubmit(true)
        if (idPatient) {
            api.patients.update(idPatient, { ...patient, confirmedData: true, city: typeof patient.city === 'object' ? patient.city.id : patient.city }).then((response) => {
                console.log(response)
                setLoadingSubmit(false);
                navigate(`/patients/${response.id}`);
            }).catch((error) => { setError(error.message); setLoadingSubmit(false); setTab('DATOS PERSONALES') })
        } else {
            api.patients.create({ ...patient, confirmedData: true, city: typeof patient.city === 'object' ? patient.city.id : patient.city }).then((response) => {
                console.log(response)
                setLoadingSubmit(false);
                navigate(`/patients/${response.id}`);
            }).catch((error) => { setError(error.message); setLoadingSubmit(false); setTab('DATOS PERSONALES') })
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                {loading ?
                    <div className="flex h-screen items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <div className="ml-6 mt-6">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <main className="flex-1">
                            <div className="py-6">
                                <div className="max-w-7xl w-full lg:w-2/3 px-4 sm:px-6 md:px-8">
                                    <Steps setTab={setTab} steps={steps} />
                                </div>
                                <div className="max-w-7xl mt-4 px-4 sm:px-6 md:px-8">
                                    <div className="bg-white w-full overflow-hidden shadow rounded-lg">
                                        <FormNewPatient
                                            handleChange={handleChange}
                                            patient={patient}
                                            radio={radio}
                                            cities={cities}
                                            error={error}
                                            handlePhones={handlePhones}
                                            removePhone={removePhone}
                                            loadingSubmit={loadingSubmit}
                                            handleSubmit={handleSubmit}
                                            handleError={handleError}
                                            tab={tab}
                                            setTab={setTab}
                                        />
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                }
            </div >
        </div >
    );
}
