import { useEffect, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CustomCombobox({ name, multiple, label, containerClassName, handleChange, options, Idx, error, value, required, create }) {
    const [query, setQuery] = useState({ name: '' })
    const [selectedPerson, setSelectedPerson] = useState([])
    const [firstRender, setFirstRender] = useState(false)

    const addPerson = (person) => {
        let aux = selectedPerson.slice();
        aux.push(person);
        setSelectedPerson(aux);
    }

    const removePerson = (person) => {
        let aux = selectedPerson.filter((element) => element.name !== person.name);
        setSelectedPerson(aux);
    }

    useEffect(() => {
        if (value?.length && !firstRender) {
            setQuery(value.name)
            setSelectedPerson(value)
            setFirstRender(true)
        }
    }, [value])

    useEffect(() => {
        if (selectedPerson) {
            handleChange(name, selectedPerson)
        }
    }, [selectedPerson])

    const filteredPeople =
        query?.name === ''
            ? options.filter((option) => {
                return (!selectedPerson.some((element) => element.name === option.name))
            })
            : options.filter((option) => {
                return (!selectedPerson.some((element) => element.name === option.name) && option.name.toLowerCase().includes(query?.name.toLowerCase()) || (option.code && option?.code.toLowerCase().includes(query?.name.toLowerCase())))
            })

    return (
        <Combobox as="div" className={containerClassName} value={selectedPerson} onChange={(value) => multiple ? (addPerson(value), setQuery({ name: '' })) : (setSelectedPerson([value], setQuery({ name: '' })))}>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">{label}{required ? <p className="font-medium text-[#F51515] inline-block ml-2"> *</p> : null}</Combobox.Label>
            <div className="relative mt-2">
                <Combobox.Input
                    className={classNames(create ? 'pr-16' : 'pr-8', "w-full rounded-md border-0 bg-white py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6")}
                    onChange={(event) => setQuery({ name: event.target.value })}
                    value={query?.name}
                />
                <Combobox.Button className={classNames(create ? 'right-8' : 'right-0', "absolute inset-y-0 flex gap-2 items-center rounded-r-md px-2 focus:outline-none")}>
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {create && <div className="absolute inset-y-0 right-0 flex gap-2 items-center rounded-r-md px-2 focus:outline-none">
                    <PlusIcon onClick={query?.name ? () => { create(query).then((response) => (addPerson(response), setQuery({ name: '' }))) } : null} className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
                </div>}
            </div>
            {filteredPeople.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredPeople.map((person) => (
                        <Combobox.Option
                            key={person.id}
                            value={person}
                            className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-primary text-white' : 'text-gray-900'
                                )
                            }
                        >
                            <>
                                <span className={classNames('block truncate')}>{`${person.code ? ('(' + person.code + ')') : ''} ${person.name}`}</span>
                            </>
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            )}
            {
                selectedPerson.length > 0 &&
                <div className='flex flex-row gap-x-3 flex-wrap'>
                    {selectedPerson.map((element) => (
                        <span className="inline-flex gap-1 relative items-center rounded-full mt-4 pr-6 bg-[#48B7E940] px-2 py-1 text-sm font-medium text-primary">
                            <p className='truncate hover:whitespace-normal max-w-[256px] hover:max-w-none'>{element.name}</p>
                            <XMarkIcon onClick={() => removePerson(element)} className="h-4 w-4 right-1 absolute cursor-pointer text-primary" aria-hidden="true" />
                        </span>
                    ))}
                </div>
            }
            {
                error && <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                    {error}
                </p>
            }
        </Combobox >
    )
}
