import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import {
    Bars3Icon,
} from '@heroicons/react/24/outline'
import { SearchIcon, MapPinIcon as SolidMapPinIcon, CakeIcon, BuildingOfficeIcon as SolidBuildingOfficeIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import DateInput from "../../components/DateInput";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import CustomCombobox from "../../components/CustomCombobox";
import Badge from "../../components/Badge";

ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    ArcElement,
);

const options = [
    { name: 'Datos generales', href: '/reports/general-data-report' },
    { name: 'Cirugías', href: '/reports/surgery-report' },
    { name: 'Estudios', href: '/reports/study-report' },
    { name: 'Medicamentos', href: '/#' },
]

const colors = [
    '#9747FF',
    '#28A5DD',
    '#EF5369',
    '#FF6C4B',
    '#F7C469',
    '#C46352',
    '#714433',
    '#412525',
    '#634258',
    '#6D6087',
    '#60A0B0',
    '#9AD1AA',
    '#307470',
    '#307470',
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MedicationPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [medication, setMedication] = useState(undefined)
    const [data, setData] = useState(undefined);
    const [dataPie, setDataPie] = useState(undefined)
    const [filters, setFilters] = useState({})
    const [fromDate, setFromDate] = useState(undefined)
    const [toDate, setToDate] = useState(undefined)
    const [medicationFilter, setMedicationFilter] = useState(undefined)

    const handleFromDate = (field, value) => setFromDate(value);
    const handleToDate = (field, value) => setToDate(value);
    const handleMedicationFilter = (field, value) => setMedicationFilter(value);

    useEffect(() => {
        api.medicines.getMulti().then((response) => setMedication(response))
        api.medicationReport.getMulti().then((response) => { setData(response); setLoading(false); })
    }, [])

    useEffect(() => {
        if (filters) {
            let aux = new URLSearchParams();
            for (var prop in filters) {
                if (Object.prototype.hasOwnProperty.call(filters, prop)) {
                    if (Array.isArray(filters[prop])) {
                        filters[prop].forEach((element) => aux.append(prop, element.id))
                    } else {
                        aux.append(prop, filters[prop])
                    }
                }
            }
            api.medicationReport.getReports(aux).then((response) => { setData(response); })
        } else {
            api.medicationReport.getReports().then((response) => { setData(response); });
        }
    }, [filters])

    const saveFromDate = () => {
        setFilters((prev) => ({ ...prev, date_after: fromDate }))
    }

    const saveToDate = () => {
        setFilters((prev) => ({ ...prev, date_before: toDate }))
    }

    const saveMedicationFilter = () => {
        setFilters((prev) => ({ ...prev, medicine: medicationFilter }))
    }

    const deleteFromDate = () => {
        let state = { ...filters }
        delete state.date_after
        setFilters(state)
        setFromDate(undefined)
    }

    const deleteToDate = () => {
        let state = { ...filters }
        delete state.date_before
        setFilters(state)
        setToDate(undefined)
    }

    const deleteDates = () => {
        let state = { ...filters }
        delete state.date_after
        delete state.date_before
        setFilters(state)
        setFromDate(undefined)
        setToDate(undefined)
    }

    const deleteAllMedicationFilter = () => {
        let state = { ...filters }
        delete state.medicine
        setFilters(state)
        setMedicationFilter(undefined)
    }

    const deleteMedicationFilter = (value) => {
        setFilters((prev) => ({ ...prev, medicine: filters.medicine.filter((element) => element.id !== value) }))
        setMedicationFilter(medicationFilter.filter((element) => element.id !== value))
    }

    useEffect(() => {
        if (data) {
            setDataPie({
                labels: data.medications.filter((element) => element.quantity > 5).map((element) => element.name),
                datasets: [
                    {
                        data: data.medications.filter((element) => element.quantity > 5).map((element) => element.quantity),
                        backgroundColor: colors,
                    }
                ]
            })
        }
    }, [data])

    const optionsPie = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left',
            },
            title: {
                display: true,
                text: 'Pacientes con orden de cirugía',
                margin: {
                    bottom: 0
                }
            }
        }
    };

    return (
        <div className="bg-gray-50 h-screen">
            <Sidebar currentScreen={'Reportes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col flex-1">
                    <main className="flex-1 bg-white">
                        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                            <button
                                type="button"
                                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="flex flex-1 pb-4 ml-6 mt-5">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm ring-0 outline-0">
                                        Medicamentos
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {options.map((element) => (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href={element.href}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            {element.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <div className="h-10 border border-[#E5E7EB] mx-8 " />
                            <div className="flex gap-6">
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Fecha
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <DateInput handleChange={handleFromDate} name='fromDate' label='Desde' />
                                                <DateInput handleChange={handleToDate} name='toDate' label='Hasta' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteDates()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => (saveToDate(), saveFromDate())} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Medicamentos
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <CustomCombobox multiple={true} required={true} options={medication} handleChange={handleMedicationFilter} name='medicine' label='Medicamentos' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteAllMedicationFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => saveMedicationFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </main>
                    <main className="bg-gray-50 px-7">
                        <div className="flex flex-wrap mt-4 gap-3">
                            {filters.date_after && <Badge text={`Desde: ${filters.date_after}`} handleDelete={() => deleteFromDate()} />}
                            {filters.date_before && <Badge text={`Hasta: ${filters.date_before}`} handleDelete={() => deleteToDate()} />}
                            {filters.medicine && filters.medicine.map((element) => (<Badge text={element.name} handleDelete={() => deleteMedicationFilter(element.id)} />))}
                        </div>
                        <div className="flex mt-4 gap-6 w-full">
                            {dataPie && <div className="bg-white p-5 w-1/2 h-[500px] rounded-lg border"><Pie options={optionsPie} data={dataPie} /></div>}
                            <div className="bg-[#683C69] rounded-lg shadow-sm py-5 px-4 text-white h-fit">
                                <p className="font-semibold leading-6">Cantidad de medicamentos <br /> recetados</p>
                                <p className="text-2xl font-semibold leading-8">{data?.totalPrescribedMedications}</p>
                            </div>
                        </div>
                    </main>
                </div >
            }
        </div >
    );
}
