import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
    CheckCircleIcon,
    UserIcon,
    ClockIcon,
    BeakerIcon,
    AcademicCapIcon,
    MapPinIcon,
    DocumentIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { SearchIcon, MapPinIcon as SolidMapPinIcon, CakeIcon, BuildingOfficeIcon as SolidBuildingOfficeIcon } from '@heroicons/react/24/solid'
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import FileInput from "../../components/FileInput";
import TextInput from "../../components/TextInput";
import Table from "../../components/Table";

const columns = [
    { name: 'uploadedBy', displayText: 'Subido por' },
    { name: 'uploadDate', displayText: 'Fecha' },
    { name: 'name', displayText: 'Nombre del archivo' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientDocumentsPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [patient, setPatient] = useState()
    const [cities, setCities] = useState([])
    const [patientDocument, setPatientDocument] = useState(undefined)
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [documents, setDocuments] = useState([])
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();
    const [error, setError] = useState(undefined)
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('BDOuserData'))?.id)
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [openDelete, setOpenDelete] = useState(false)
    const { idPatient } = useParams()

    const handleChange = (field, value) => setPatientDocument((prev) => ({ ...prev, [field]: value }));
    const handleSubmit = async () => {
        setSuccess(false);
        setLoadingSubmit(true);
        let formData = new FormData();
        let request = { ...patientDocument, patient: idPatient, uploadedBy: userId, uploadDate: new Date().toISOString().split('T')[0] }
        for (var key in request) {
            if (request[key]) {
                formData.append(key, request[key]);
            }
        }
        api.documents.create(formData).then((response) => {
            console.log(response)
            setLoadingSubmit(false);
            setSuccess(true);
            api.documents.getMulti(null, idPatient).then((response) => {
                setDocuments(response.map((element) => ({ id: element.id, name: element.name, uploadedBy: element.uploadedBy.name, uploadDate: new Date(element.uploadDate).toLocaleDateString(), document: element.document })))
            })
            setPatientDocument({ document: '', name: '' })
        }).catch((error) => { setError(error.message); setLoadingSubmit(false); })
    }

    const handleDelete = async () => {
        console.log(selectedDocument)
        api.documents.delete(selectedDocument.id).then((response) => {
            api.documents.getMulti(null, idPatient).then((response) => {
                setDocuments(response.map((element) => ({ id: element.id, name: element.name, uploadedBy: element.uploadedBy.name, uploadDate: new Date(element.uploadDate).toLocaleDateString(), document: element.document })))
                setOpenDelete(false);
                setSelectedDocument(undefined);
            })
        }).catch((error) => setError(error.message))
    }

    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: 'Detalle  del paciente', href: `/patients/${idPatient}`, current: false },
        { name: 'Adjuntar archivos', href: `#`, current: true },
    ]

    useEffect(() => {
        api.cities.getMulti().then((resp) => {
            setCities(resp);
            api.patients.getOne(idPatient).then((response) => {
                setPatient({ ...response, name: response.firstName + " " + response.lastName, city: resp.find((city) => city.id === (response.city + 1))?.name });
                setLoading(false);
            })
            api.documents.getMulti(null, idPatient).then((response) => {
                setDocuments(response.map((element) => ({ id: element.id, name: element.name, uploadedBy: element.uploadedBy.name, uploadDate: new Date(element.uploadDate).toLocaleDateString(), document: element.document })))
            })
        })
    }, [])

    const deletionModal = (
        <>
            < div >
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Eliminar archivo
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres eliminar {selectedDocument?.name}?
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red text-base font-medium text-white hover:bg-red-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleDelete()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => (setOpenDelete(false), setSelectedDocument(undefined))}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    return (
        <div className="bg-gray-50 h-screen">
            <Modal size='sm:max-w-lg w-full' content={deletionModal} open={openDelete} setOpen={setOpenDelete} />
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col bg-gray-100 flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="flex flex-col flex-1 pb-4 ml-6 mt-6">
                        <Breadcrumbs pages={pages} />
                        <div className="ml-4">
                            <h1 className="mt-6 text-3xl font-bold leading-9 text-gray-900">{patient?.name}</h1>
                            <div className="flex flex-wrap items-center mt-2 gap-y-1">
                                <div>
                                    <span className="text-sm leading-5 font-medium text-gray-500">C.I. {patient?.govId}</span>
                                </div>
                                <div className="ml-6">
                                    <CakeIcon className="inline-block h-5 w-5 text-gray-500" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.age} años
                                    </span>
                                </div>
                                {patient.city ?
                                    <div className="ml-6">
                                        <SolidMapPinIcon className="inline-block h-5 w-5 text-gray-500" />
                                        <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                            {patient.city}
                                        </span>
                                    </div>
                                    : null
                                }
                                <div className="ml-4">
                                    <span className="text-sm leading-5font-medium text-gray-500">| </span>
                                    <span className="text-sm leading-5 ml-4 font-medium text-gray-500">Última visita: {patient?.last_visit ? patient.last_visit : '--'} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className="flex-1 bg-gray-50 px-6 py-10">
                        <p className="font-bold text-[30px] text-[#4B5563] leading-[36px]">Archivos adjuntos</p>
                        <div className="bg-white mt-4 px-8 py-6 max-w-screen shadow border flex-1 border-gray-300 rounded-lg">
                            <div className="flex flex-row gap-x-6">
                                <FileInput errorMessage={error?.document} labelClassName='text-sm font-medium leading-[20px] text-[#374151]' label='Archivo' buttonText='Adjuntar archivo' handleChange={handleChange} name='document' value={patientDocument?.document} />
                                <TextInput value={patientDocument?.name} handleChange={handleChange} name='name' containerClassName='w-1/3' label='Nombre del archivo' />
                                <button type='button' onClick={() => handleSubmit()} className="flex justify-center h-fit mt-6 gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary bg-white">
                                    Añadir
                                </button>
                            </div>
                            <div className="mt-6">
                                <Table actiontext='Ver' delete={true} setSelectedDocument={setSelectedDocument} setOpenDelete={setOpenDelete} document={true} columns={columns} rows={documents} />
                            </div>
                        </div>
                    </main>
                </div>
            }
        </div >
    );
}
