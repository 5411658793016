import React, { useState, useEffect } from "react";
import BancoLogo from "../assets/LogoLarge.png";
import useWebSocket, { ReadyState } from "react-use-websocket";
import ItemProvider from "../utils";
import audio from "../assets/alerta.mp3";
import api from "../api";

const audioAlert = new Audio(audio);

const WaitingRoomPage = () => {
    const [date, setDate] = useState(new Date());
    let dateOptions = { weekday: "long", month: "long", day: "numeric" };
    const [connectionStatus, setConnectionStatus] = useState(null);
    const url = `${process.env.REACT_APP_WS_URL}`;
    const [calling, setCalling] = useState(false);
    const [callingInfo, setCallingInfo] = useState(null);
    const [names, setNames] = useState({
        counter: -1,
        queue: [],
    });
    const [notice, setNotice] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket(url, {
        onOpen: () => setConnectionStatus("open"),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    const speechHandler = (data) => {
        const msg = new SpeechSynthesisUtterance();
        msg.rate = 0.4;
        msg.text = `${data.patient} ${data.medical_office}`;
        msg.onstart = () => {
            console.log("empieza");
            setCallingInfo(data);
            setCalling(true);

            // Aquí puedes realizar acciones adicionales al inicio de la síntesis
        };
        msg.onend = () => {
            console.log("termino");
            setTimeout(() => {
                setCallingInfo({});
                setCalling(false);
            }, 15000);
        };

        msg.onerror = (error) => {
            console.error("Error en la síntesis de voz:", error);
            setCalling(false);
        };
        // Prevent garbage collection
        console.log(msg);

        window.speechSynthesis.speak(msg);
    };

    useEffect(() => {
        let timer = setInterval(() => setDate(new Date(), 60000));
        return function cleanUp() {
            clearInterval(timer);
        };
    });

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage(
                {
                    action: "join_waiting_room",
                },
                false
            );
        }
    }, [readyState]);

    useEffect(() => {
        console.log(lastJsonMessage);
        if (readyState === ReadyState.OPEN) {
            if (lastJsonMessage.type === "call") {
                setNames({
                    ...names,
                    counter: names.counter + 1,
                    queue: [...names.queue, lastJsonMessage.data],
                });
                //speechHandler(lastJsonMessage.data);
            }
        }
    }, [lastJsonMessage]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        let counter = names.counter;
        if (counter >= 0) {
            audioAlert.play();
            setTimeout(() => {
                speechHandler(names.queue[counter]);
                counter = counter - 1;
            }, 2000);
        } else if (names.queue.length > 0) {
            setNames({ counter: -1, queue: [] });
        }
    }, [names]);

    useEffect(() => {
        const getInfo = async () => {
            setNotice(await api.notices.getOne(1));
            setBackgroundImage(await api.images.getOne(1));
        };
        getInfo();
        return () => {
            sendJsonMessage(
                {
                    action: "logout",
                },
                false
            );
        };
    }, []);

    return (
        <div className="min-h-screen h-full flex flex-col justify-center py-4 bg-gray-50 ">
            {calling ? (
                <div>
                    {/* <img
                        className="w-[12%] mx-auto mb-20"
                        src={BancoLogo}
                        alt="Banco de Ojos"
                    /> */}
                    <div>
                        <p className="font-bold text-[56px] text-[#75C9EE] text-center">
                            Paciente
                        </p>
                        <p className="font-bold text-[124px] text-[#363636] text-center mb-5">
                            {callingInfo.patient}
                        </p>
                        <p className="font-bold text-[64px] text-[#363636] text-center mb-5">
                            {callingInfo.medical_office}
                        </p>
                    </div>
                </div>
            ) : (notice !== null && notice?.show) ||
              (backgroundImage !== null && backgroundImage?.show) ? (
                <div>
                    <div>
                        {notice !== null ? (
                            <div
                                className="mx-10 px-4"
                                style={{ maxWidth: "70%" }}
                            >
                                <p className="font-bold text-[56px] text-[#75C9EE]">
                                    {notice?.title}
                                </p>
                                <p className="font-bold text-[64px] text-[#363636] mb-5">
                                    {notice?.description}
                                </p>
                            </div>
                        ) : (
                            <img
                                src={
                                    "https://bancodeojosapi.staging.girolabs.cloud/media/glasses-optical-objects-white-background.jpg"
                                }
                                style={{ width: "75%", height: "100%" }}
                            />
                        )}
                    </div>
                    <div>
                        <div className="absolute top-0 right-0 p-14">
                            <img
                                className="w-[200px] mx-auto"
                                src={BancoLogo}
                                alt="Banco de Ojos"
                            />
                        </div>
                        <div className="absolute bottom-0 right-0 p-14">
                            <p className="font-bold text-3xl text-[#363636] leading-[36px] mb-2">
                                {date.getHours()}:
                                {(date.getMinutes() < 10 ? "0" : "") +
                                    date.getMinutes()}
                            </p>
                            <p className="text-3xl text-[#363636] leading-[36px]">
                                {capitalizeFirstLetter(
                                    date.toLocaleDateString(
                                        "es-PY",
                                        dateOptions
                                    )
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex justify-center">
                        <img
                            className="w-3/6 mx-auto"
                            src={BancoLogo}
                            alt="Banco de Ojos"
                        />
                    </div>
                    <div className="absolute bottom-0 right-0 p-14">
                        <p className="font-bold text-3xl text-[#363636] leading-[36px] mb-2">
                            {date.getHours()}:
                            {(date.getMinutes() < 10 ? "0" : "") +
                                date.getMinutes()}
                        </p>
                        <p className="text-3xl text-[#363636] leading-[36px]">
                            {capitalizeFirstLetter(
                                date.toLocaleDateString("es-PY", dateOptions)
                            )}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WaitingRoomPage;
