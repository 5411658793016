import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
    CheckCircleIcon,
    UserIcon,
    ClockIcon,
    BeakerIcon,
    AcademicCapIcon,
    MapPinIcon,
    DocumentIcon
} from '@heroicons/react/24/outline'
import { SearchIcon, MapPinIcon as SolidMapPinIcon, CakeIcon, BuildingOfficeIcon as SolidBuildingOfficeIcon } from '@heroicons/react/24/solid'
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import SelectInput from "../../components/SelectInput";
import Table from "../../components/Table";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const visionChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Visión',
        },
    },
};

export const tensionChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Tensión',
        },
    },
};

const options = [
    { name: 'Lista de consultas', value: 1 },
    { name: 'Resumen', value: 2 }
]

const sortOptions = [
    { name: 'Fecha descendente', value: 1 },
    { name: 'Fecha ascendente', value: 2 },
]

const visionValues = [
    '',
    '20/10',
    '20/15',
    '20/20',
    '20/25',
    '20/30',
    '20/40',
    '20/50',
    '20/60',
    '20/70',
    '20/80',
    '20/100',
    '20/150',
    '20/200',
    '20/400',
    'CD',
    'MM',
    'SPL',
    'BPL',
    'MPL',
    'NPL',
]

const columns = [
    { name: 'index', displayText: '#' },
    { name: 'consultationDate', displayText: 'Fecha de consulta' },
    { name: 'diagnoses', displayText: 'Diagnostico' },
    { name: 'doctor', displayText: 'Doctor' },
    { name: 'prescription', displayText: 'Receta' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ConsultationHistoryPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [cities, setCities] = useState([])
    const [patient, setPatient] = useState()
    const [loading, setLoading] = useState(true)
    const [vision, setVision] = useState(undefined);
    const [tension, setTension] = useState(undefined);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { idPatient } = useParams()
    const [history, setHistory] = useState(undefined)
    const [index, setIndex] = useState(0)
    const [mode, setMode] = useState(1)
    const [sort, setSort] = useState(1)
    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: `${patient?.name}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Historial de consultas', href: `#`, current: true },
    ]

    const handleMode = (field, value) => {
        setMode(value)
    }

    const handleSort = (field, value) => {
        setSort(value)
    }

    useEffect(() => {
        api.patients.getOne(idPatient).then((response) => {
            setPatient({
                ...response,
                name: response.firstName + " " + response.lastName,
            });
        }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        if (sort === 2)
            api.patients.getHistory(idPatient, '-date').then((response) => (setHistory({ ...response, consultationList: response.consultationList ? response.consultationList.map((element, idx) => ({ ...element, index: idx + 1, prescription: element.prescription ? 'Si' : 'No' })) : null }), setLoading(false)))
        else
            api.patients.getHistory(idPatient, 'date').then((response) => (setHistory({ ...response, consultationList: response.consultationList ? response.consultationList.map((element, idx) => ({ ...element, index: idx + 1, prescription: element.prescription ? 'Si' : 'No' })) : null }), setLoading(false)))
    }, [sort])

    useEffect(() => {
        api.visionEvolution.getOne(idPatient).then((response) => setVision(response))
        api.tensionEvolution.getOne(idPatient).then((response) => setTension(response))
    }, [])

    return (
        <div className="bg-gray-50 h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col bg-gray-100 flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex flex-col flex-1 pb-4 ml-6 mt-6">
                        <Breadcrumbs pages={pages} />
                        <div className="ml-4">
                        </div>
                    </div>
                    <main className="flex-1 bg-gray-50">
                        <div className="flex flex-row w-full gap-32 px-4 py-6">
                            <div className="flex flex-row gap-1">
                                <div className="border py-5 px-7 rounded-[8px] border-[#EF5369] bg-white flex flex-col gap-3">
                                    <p className="text-[#EF5369] font-semibold">Ultima consulta</p>
                                    <p className="text-[#363636] font-semibold text-2xl">{history.lastConsultationDate ? history.lastConsultationDate : '--'}</p>
                                </div>
                                <div className="border py-5 px-7 rounded-[8px] border-[#28A5DD] bg-white flex flex-col gap-3">
                                    <p className="text-[#28A5DD] font-semibold">N° total de consultas</p>
                                    <p className="text-[#363636] font-semibold text-2xl">{history.consultationCount ? history.consultationCount : '--'}</p>
                                </div>
                                <div className="border py-5 px-7 rounded-[8px] border-[#F69400] bg-white flex flex-col gap-3">
                                    <p className="text-[#F69400] font-semibold">Última consulta con</p>
                                    <p className="text-[#363636] font-semibold text-2xl">{history.lastDoctor ? history.lastDoctor : '--'}</p>
                                </div>
                            </div>
                            <SelectInput containerStyle='w-1/6' className='shadow-sm w-fit bg-[#C8E5F2] mt-1 mx-auto pr-6 border border-[#C8E5F2] block sm:text-sm rounded-md text-[#48B7E9] font-bold leading-6' options={options.map((element) => (<option key={element.value} value={element.value} label={element.name}>{element.name}</option>))} name='mode' handleChange={handleMode} />
                        </div>
                        {mode === 2 ?
                            <div className="grid grid-cols-2 px-5">
                                <div className="mt-8">
                                    <p className="text-lg font-medium leading-6">Resumen de consultas</p>
                                    <div className="border border-[#E5E7EB] w-5/6 mt-2" />
                                    <div className="flex gap-3 mt-2">
                                        <p className="whitespace-nowrap mt-2">Ordenar por</p>
                                        <SelectInput handleChange={handleSort} className='shadow-sm w-3/5 self-start mt-1 pr-6 border border-gray-300 block sm:text-sm rounded-md' options={sortOptions.map((element) => (<option key={element.value} value={element.value} label={element.name}>{element.name}</option>))} />
                                    </div>
                                    <div className="flex gap-6 mt-7">
                                        <button onClick={index === 0 ? null : () => setIndex((prev) => prev - 1)} className={classNames(index === 0 ? 'bg-[#E5E7EB]' : 'bg-[#75C9EE]', "py-2 px-4 text-white rounded-md shadow-sm")}>
                                            Consulta anterior
                                        </button>
                                        <button onClick={history.consultationList && index === history?.consultationList?.length - 1 ? null : () => setIndex((prev) => prev + 1)} className={classNames((index === history?.consultationList?.length - 1 || !history.consultationList) ? 'bg-[#E5E7EB]' : 'bg-[#75C9EE]', "py-2 px-4 text-white rounded-md shadow-sm")}>
                                            Siguiente consulta
                                        </button>
                                    </div>
                                    {history.consultationList ?
                                        <>
                                            <ul className="list-disc mt-5 text-[#48B7E9] pl-4 font-bold leading-5"><li>{history?.consultationList[index]?.consultationDate}</li></ul>
                                            <div className="flex gap-5 mt-4">
                                                <p className="text-[#6B7280]">Diagnóstico</p>
                                                <p>{history?.consultationList[index]?.diagnoses}</p>
                                            </div>
                                            <div className="flex flex-col mt-9">
                                                <p className="text-[#6B7280]">Motivo</p>
                                                <p>{history?.consultationList[index]?.visionTension?.motiveOfConsultation}</p>
                                            </div>
                                            <div className="grid grid-cols-3 mt-[52px]">
                                                <div>
                                                    <p>Visión y tensión</p>
                                                </div>
                                                <div>
                                                    <p>Con corrección</p>
                                                    <div className="grid grid-cols-2">
                                                        <div>
                                                            <p>OI</p>
                                                            <p>{history?.consultationList[index]?.visionTension?.visionWithCorrectionLeft ? visionValues[history.consultationList[index].visionTension.visionWithCorrectionLeft] : '--'}</p>
                                                        </div>
                                                        <div>
                                                            <p>OD</p>
                                                            <p>{history?.consultationList[index]?.visionTension?.visionWithCorrectionRight ? visionValues[history.consultationList[index].visionTension.visionWithCorrectionLeft] : '--'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Sin corrección</p>
                                                    <div className="grid grid-cols-2">
                                                        <div>
                                                            <p>OI</p>
                                                            <p>{history?.consultationList[index]?.visionTension?.visionWithoutCorrectionLeft ? visionValues[history.consultationList[index].visionTension.visionWithoutCorrectionLeft] : '--'}</p>
                                                        </div>
                                                        <div>
                                                            <p>OD</p>
                                                            <p>{history?.consultationList[index]?.visionTension.visionWithoutCorrectionRight ? visionValues[history.consultationList[index].visionTension.visionWithoutCorrectionLeft] : '--'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <p className="mt-4">
                                            No se han encontrado consultas
                                        </p>
                                    }
                                </div>
                                {/* <div>
                                <Line options={visionChartOptions} data={data} />
                                <Line options={tensionChartOptions} data={data} />
                            </div> */}
                            </div>
                            :
                            <>
                                {history.consultationList ? <div className="px-5 w-5/6 mt-10">
                                    <Table actiontext='Detalle' module={`patients/${idPatient}/consultations`} columns={columns} rows={history?.consultationList} />
                                </div> :
                                    <p className="mt-4 px-5">
                                        No se han encontrado consultas
                                    </p>
                                }</>
                        }
                    </main>
                </div >
            }
        </div >
    );
}
