import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
    CheckCircleIcon,
    UserIcon,
    ClockIcon,
    BeakerIcon,
    AcademicCapIcon,
    MapPinIcon,
    DocumentIcon
} from '@heroicons/react/24/outline'
import { SearchIcon, MapPinIcon as SolidMapPinIcon, CakeIcon, BuildingOfficeIcon as SolidBuildingOfficeIcon } from '@heroicons/react/24/solid'
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import SimpleCombobox from "../../components/SimpleCombobox";
import Checkbox from "../../components/Checkbox";
import InlineCheckbox from "../../components/InlineCheckbox"

const actions = [
    {
        name: 'Nueva consulta',
        background: "bg-green-100",
        href: "/new",
        icon: <CheckCircleIcon className="h-6 w-6 text-green-600" />,
    },
    {
        name: 'Datos del paciente',
        background: "bg-purple-200",
        href: "/edit",
        icon: <UserIcon className="h-6 w-6 text-purple-700" />
    },
    {
        name: 'Historial de consultas',
        background: "bg-blue-200",
        href: "/history",
        icon: <ClockIcon className="h-6 w-6 text-blue-700" />
    },
    {
        name: 'Documentos adjuntos',
        background: "bg-green-100",
        href: "/documents",
        icon: <DocumentIcon className="h-6 w-6 text-[#728D57]" />
    },
]

const pages = [
    { name: 'Pacientes', href: '/patients', current: false },
    { name: 'Detalle  del paciente', href: `#`, current: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientDetailPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [cities, setCities] = useState([])
    const [patient, setPatient] = useState()
    const [doctors, setDoctors] = useState([])
    const [doctor, setDoctor] = useState(undefined)
    const [isUrgent, setIsUrgent] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openSelect, setOpenSelect] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { idPatient } = useParams()

    useEffect(() => {
        api.cities.getMulti().then((resp) => {
            setCities(resp);
            api.patients.getOne(idPatient).then((response) => {
                console.log(response)
                setPatient({ ...response, name: response.firstName + " " + response.lastName, city: resp.find((city) => city.id === (response.city))?.name });
                setOpen(!response.confirmedData);
                setLoading(false);
            })
        })
        api.users.getDoctors().then((response) => setDoctors(response.map((element) => ({ name: `${element.sex === 'Masculino' ? 'Dr.' : 'Dra.'} ${element.firstName} ${element.lastName}`, ...element }))))
    }, [])

    const handleDoctor = (field, value) => {
        setDoctor(value)
    }

    const handleIsUrgent = (field, value) => {
        setIsUrgent(value)
    }

    const handleSubmit = () => {
        api.turns.create({ doctorGovId: doctor.govId, patientGovId: patient.govId, isUrgent: isUrgent }).then(() => (setOpenConfirmation(false), setOpenSuccess(true))).catch((error) => error.code === 'serverError' ? (setErrorMessage('Error 500: Error del servidor'), setLoading(false)) : (setErrorMessage(error?.message), setLoading(false)))
    }

    useEffect(() => {
        if (!openConfirmation)
            setErrorMessage('')
    }, [openConfirmation])

    useEffect(() => {
        if (!openSelect)
            setError('')
    }, [openSelect])

    const validation = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Validacion de datos
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Es necesario validar los datos del paciente para poder continuar
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => navigate(`/patients/${idPatient}/edit`)}
                >
                    Continuar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => navigate('/patients')}
                >
                    Volver
                </button>
            </div>
        </>
    )

    const confirmation = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Confirmación
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            ¿Está seguro de que quiere agregar el paciente al turnero?
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto">
                            {errorMessage && Object.keys(errorMessage).map((element) => (<p className="mt-2 text-sm text-red-600">{errorMessage[element]}</p>))}
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => handleSubmit()}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => (setOpenConfirmation(false), setOpenSelect(true))}
                >
                    Regresar
                </button>
            </div>
        </>
    )

    const selectDoctor = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Agregar al turnero
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Seleccione el doctor que atenderá al paciente
                        </label>
                        <div className="flex gap-3 relative rounded-md mx-auto mt-3">
                            <SimpleCombobox value={doctor} containerClassName='w-2/3 shadow-sm' handleChange={handleDoctor} name="doctor" options={doctors} />
                            <InlineCheckbox containerClassName='relative flex pr-2 items-start mt-3' className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" value={isUrgent} handleChange={handleIsUrgent} name='isUrgent' label='Es Urgencia' />
                        </div>
                        {error && <p className="mt-2 text-sm text-red-600">
                            {error}
                        </p>}
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => doctor ? (setOpenSelect(false), setOpenConfirmation(true)) : setError('Debe seleccionar un doctor para continuar')}
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => (setDoctor(undefined), setOpenSelect(false))}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const success = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Paciente agregado
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            El paciente fue agregado exitosamente al turnero.
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() => setOpenSuccess(false)}
                >
                    Aceptar
                </button>
            </div>
        </>
    )

    return (
        <div className="bg-gray-50 h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col bg-gray-100 flex-1">
                    <Modal size='sm:max-w-lg w-full' content={validation} open={open} setOpen={setOpen} />
                    <Modal size='sm:max-w-lg w-full' content={confirmation} open={openConfirmation} setOpen={setOpenConfirmation} />
                    <Modal size='sm:max-w-lg w-full' content={selectDoctor} open={openSelect} setOpen={setOpenSelect} />
                    <Modal size='sm:max-w-lg w-full' content={success} open={openSuccess} setOpen={setOpenSuccess} />
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="flex flex-col flex-1 pb-4 ml-6 mt-6">
                        <Breadcrumbs pages={pages} />
                        <div className="ml-4">
                            <h1 className="mt-6 text-3xl font-bold leading-9 text-gray-900">{patient?.name}</h1>
                            <div className="flex flex-wrap items-center mt-2 gap-y-1">
                                <div>
                                    <span className="text-sm leading-5 font-medium text-gray-500">C.I. {patient?.govId}</span>
                                </div>
                                <div className="ml-6">
                                    <CakeIcon className="inline-block h-5 w-5 text-gray-500" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.age} años
                                    </span>
                                </div>
                                {patient.city ?
                                    <div className="ml-6">
                                        <SolidMapPinIcon className="inline-block h-5 w-5 text-gray-500" />
                                        <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                            {patient.city}
                                        </span>
                                    </div>
                                    : null
                                }
                                <div className="ml-4">
                                    <span className="text-sm leading-5font-medium text-gray-500">| </span>
                                    <span className="text-sm leading-5 ml-4 font-medium text-gray-500">Última visita: {patient?.lastConsultationDate ? patient.lastConsultationDate : '--'} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className="flex-1 bg-gray-50">
                        <div className="grid grid-cols-1 w-full justify-center items-center gap-4 px-32 py-16">
                            {actions.map((action) => (
                                <div
                                    key={action.name}
                                    className="relative w-3/5 mx-auto rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                                >
                                    <div className={`flex-shrink-0 rounded p-2 ${action.background}`}>
                                        {action.icon}
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            <p className="text-sm font-medium text-gray-900">{action.name}</p>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                            <div
                                key='Agregar paciente al turnero'
                                className="relative w-3/5 mx-auto rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                            >
                                <div className='flex-shrink-0 rounded p-2 bg-red-100'>
                                    <DocumentIcon className="h-6 w-6 text-[#bb4c4c]" />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <button onClick={() => setOpenSelect(true)} className="focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        <p className="text-sm font-medium text-gray-900">Agregar paciente al turnero</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
        </div >
    );
}
