import React from "react";
import BancoLogo from "../../assets/Logo.png";

const CallerStep3 = () => {
    return (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="w-48 mx-auto" src={BancoLogo} alt="Banco de Ojos" />
            <h2 className="text-center text-[30px] font-bold leading-9 text-primary mb-10">
                Ya no quedan pacientes por atender
            </h2>
        </div>
    );
};

export default CallerStep3;
