import React from 'react';

const PdfIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="file-pdf-regular-1">
                <path id="Vector" d="M3 21.75H4.5V24H3C1.34531 24 0 22.6547 0 21V3C0 1.34531 1.34531 0 3 0H10.7578C11.5547 0 12.3187 0.314063 12.8812 0.876563L17.1234 5.11875C17.6859 5.68125 18 6.44531 18 7.24219V13.5H15.75V7.5H12C11.1703 7.5 10.5 6.82969 10.5 6V2.25H3C2.5875 2.25 2.25 2.5875 2.25 3V21C2.25 21.4125 2.5875 21.75 3 21.75ZM8.25 16.5H9.75C11.1984 16.5 12.375 17.6766 12.375 19.125C12.375 20.5734 11.1984 21.75 9.75 21.75H9V23.25C9 23.6625 8.6625 24 8.25 24C7.8375 24 7.5 23.6625 7.5 23.25V21V17.25C7.5 16.8375 7.8375 16.5 8.25 16.5ZM9.75 20.25C10.3734 20.25 10.875 19.7484 10.875 19.125C10.875 18.5016 10.3734 18 9.75 18H9V20.25H9.75ZM14.25 16.5H15.75C16.9922 16.5 18 17.5078 18 18.75V21.75C18 22.9922 16.9922 24 15.75 24H14.25C13.8375 24 13.5 23.6625 13.5 23.25V17.25C13.5 16.8375 13.8375 16.5 14.25 16.5ZM15.75 22.5C16.1625 22.5 16.5 22.1625 16.5 21.75V18.75C16.5 18.3375 16.1625 18 15.75 18H15V22.5H15.75ZM19.5 17.25C19.5 16.8375 19.8375 16.5 20.25 16.5H22.5C22.9125 16.5 23.25 16.8375 23.25 17.25C23.25 17.6625 22.9125 18 22.5 18H21V19.5H22.5C22.9125 19.5 23.25 19.8375 23.25 20.25C23.25 20.6625 22.9125 21 22.5 21H21V23.25C21 23.6625 20.6625 24 20.25 24C19.8375 24 19.5 23.6625 19.5 23.25V20.25V17.25Z" fill="black" />
            </g>
        </svg>
    );
};

export default PdfIcon;